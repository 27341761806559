/**
 * Imports
 */
import _ from 'lodash'
import { tools } from 'constants/tools'
import { filterData, sortData } from 'helpers/sortAndFilter'
import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { hasBlobData } from 'helpers/reportBuilder/hasBlobData'
import { getCrosstabFill } from 'helpers/reportBuilder/getCrosstabFill'
import { getDecimalPointNumberFromLeadingZeroNumber } from 'helpers/reportBuilder/getDecimalPointNumberFromLeadingZeroNumber'
import { getOptionLabel } from 'helpers/reports/getOptionLabel'

import {
	getSegmentTotalSupportPercentageKey,
	getSegmentTotalSupportCompletesKey,
	getSegmentTotalSupportDecimalPercentageKey,
} from 'store/reportBuilder'
import { COLORS } from 'constants/colors'
import { GRAPH_TYPE_OPTIONS, REPORT_TYPES, REPORT_BLOB_TYPES } from 'constants/reports'

/**
 * Munge Functions
 */
const mungeIdeas = (options, idsSegments, legendState) => {
	const chartOptions = options.map(option => {
		const percentAndCompletes = _.flatten(
			idsSegments.map(idSegment => {
				const percent = option[getSegmentTotalSupportDecimalPercentageKey(idSegment)]
				const completes = option[getSegmentTotalSupportCompletesKey(idSegment)]

				return [percent, completes]
			}),
		)

		return {
			..._.pick(
				option,
				_.flatMap(idsSegments, idSegment => [
					getSegmentTotalSupportPercentageKey(idSegment),
					getSegmentTotalSupportCompletesKey(idSegment),
				]),
			),
			letter: option.letter,
			name: option.label,
			code: option.code,
			themeName: null,
			fill: option.color,
			id: option.idOption,
			shown: true,
			unit: '%',
			toExport: () => [option.letter, option.label, ...percentAndCompletes, option.code],
		}
	})

	return sortData(filterData(chartOptions, legendState.filtered), legendState.sorted)
}

/**
 * Calculators
 */
const getSegmentOption = (idOption, totalSegmentData) =>
	totalSegmentData.options.find(option => option.idOption === idOption)

const getAnswerPercentage = (fraction, whole) =>
	whole > 0 ? Math.round(Number(Math.round((fraction / whole) * 10000) / 100)) : 0

export const calculateLegendIdeas = (
	idStudy,
	reportType,
	datasetOptions,
	slideSettings,
	legendState,
	blobData,
) => {
	if (reportType !== REPORT_TYPES.CHOICE_ANSWERS) {
		return []
	}

	if (slideSettings === undefined) return []

	const { idStudyObject, idsSegments, options: hiddenOptions } = slideSettings

	const dataArray = idsSegments.map(
		idSegment =>
			blobData[
				getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.CHOICE_STATISTICS, idSegment)
			],
	)
	const totalSegmentData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.CHOICE_STATISTICS,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	if (hasBlobData([...dataArray, totalSegmentData], 'options') === false) {
		return []
	}

	const dataset = datasetOptions.find(selector => selector.value === idStudyObject)

	const newOptions = dataset.options
		.map((option, index) => {
			const totalSegmentOption = getSegmentOption(option.id, totalSegmentData)
			const color =
				slideSettings.graphType === GRAPH_TYPE_OPTIONS.BAR
					? COLORS.GREY_45
					: getCrosstabFill(reportType, index, COLORS.GREY_45, dataset.options.length)

			const chartOption = {
				idOption: option.id,
				color,
				letter: totalSegmentOption.letter,
				label: getOptionLabel(option, slideSettings),
				code: option.code,
			}

			idsSegments.forEach(idSegment => {
				const segmentData =
					blobData[
						getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.CHOICE_STATISTICS, idSegment)
					]
				const segmentOption = getSegmentOption(option.id, segmentData)

				const percentage = getAnswerPercentage(
					segmentOption.respondentsCount,
					segmentData.respondentsCount,
				)
				const percentageDecimal = getDecimalPointNumberFromLeadingZeroNumber(
					segmentOption.respondentsCount / segmentData.respondentsCount,
				)

				chartOption[getSegmentTotalSupportCompletesKey(idSegment)] = Number(
					segmentOption.respondentsCount,
				)
				chartOption[getSegmentTotalSupportDecimalPercentageKey(idSegment)] = Number(
					percentageDecimal,
				)
				chartOption[getSegmentTotalSupportPercentageKey(idSegment)] = Number(percentage)
			})

			return chartOption
		})
		.filter(option => option !== null && hiddenOptions.includes(option.idOption) === false)

	return mungeIdeas(newOptions, idsSegments, legendState)
}
