import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import _ from 'lodash'

import { Form, Formik } from 'formik'
import { CONTACT_US } from 'gql/mutations/user'
import { useMutation } from 'react-apollo'

import { formatEmail } from 'helpers/formatEmail'

import Field from 'components/_formik/_complex/Field'
import Input from 'components/_formik/_base/Input'
import TextArea from 'components/_formik/_base/Textarea'
import Button from 'components/_scaffolding/_input/Button'
import handleFormikSubmit from 'components/_formik/handleFormikSubmit'

import classes from './ContactUsForm.module.scss'

const ContactUsForm = ({ email, fullName, showNotification, handleCloseClick }) => {
	const intl = useIntl()

	const onCompleted = () => {
		showNotification(
			{
				id: 'contact-us-form-success',
				type: 'message',
				message: intl.formatMessage({ id: 'message_sent' }),
				buttons: ['okay'],
			},
			true,
		)
		handleCloseClick()
	}

	const onError = () => {
		showNotification(
			{
				id: 'contact-us-form-error',
				type: 'error',
				message: intl.formatMessage({ id: 'request_error_notification_short' }),
				buttons: ['dismiss'],
			},
			true,
		)
	}

	const prepareData = values => ({
		contactUsInput: {
			email: formatEmail(values.email),
			fullName: values.fullName.trim(),
			message: values.message.trim(),
		},
	})

	const mutation = CONTACT_US()

	const [contactUs] = useMutation(mutation.mutation, mutation)

	const handleSubmit = mutate => (values, actions) => {
		handleFormikSubmit(mutate, prepareData(values), values, actions, onError, onCompleted)
	}

	return (
		<Formik
			initialValues={{
				email: email,
				fullName: fullName,
			}}
			onSubmit={handleSubmit(contactUs)}
		>
			{formProps => (
				<Form>
					<Field
						component={Input}
						placeholder={intl.formatMessage({ id: 'contact_us_form.name_placeholder' })}
						label={{ label: intl.formatMessage({ id: 'name' }), className: classes.label }}
						validate={{ required: true, maxLength: 50 }}
						className={classes.input}
						name="fullName"
						type="text"
					/>
					<Field
						component={Input}
						placeholder={intl.formatMessage({ id: 'contact_us_form.email_placeholder' })}
						label={{
							label: intl.formatMessage({ id: 'email_header' }),
							className: classes.label,
						}}
						validate={{ required: true, validEmail: true }}
						className={classes.input}
						name="email"
						type="text"
					/>
					<Field
						component={TextArea}
						label={{
							label: intl.formatMessage({ id: 'message.title' }),
							className: classes.label,
						}}
						validate={{ required: true, maxLength: 500 }}
						className={classes.input}
						name="message"
						type="text"
					/>
					<div className={classes.button}>
						<Button
							onClick={_.noop}
							title={intl.formatMessage({
								id: 'contact_us_form.button',
							})}
							isLoading={formProps.isSubmitting}
						/>
					</div>
				</Form>
			)}
		</Formik>
	)
}

ContactUsForm.propTypes = {
	email: PropTypes.string.isRequired,
	fullName: PropTypes.string.isRequired,
	showNotification: PropTypes.func.isRequired,
	handleCloseClick: PropTypes.func.isRequired,
}

export default ContactUsForm
