import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'

import { ENDSTUDY_TYPES } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/END_STUDY'
import { URL_VALIDATION_SKIP_PREFIX } from 'constants/regex'

import { panelShape } from 'constants/panels/panelShape'
import { PROVIDER_TYPE } from 'constants/panels/providerType'

import { filterOutGsPreviewPanel } from 'helpers/panels/filterOutGsPreviewPanel'

import classes from './PanelRedirects.module.scss'

const getPanelRedirectBySubtype = (subtype, forcedRedirects) => {
	switch (subtype) {
		case ENDSTUDY_TYPES.COMPLETE:
			return _.get(forcedRedirects, 'complete', '')
		case ENDSTUDY_TYPES.TERMINATE:
			return _.get(forcedRedirects, 'terminate', '')
		case ENDSTUDY_TYPES.OVERQUOTA:
			return _.get(forcedRedirects, 'overquota', '')
		case ENDSTUDY_TYPES.QUALITY_TERMINATE:
			return _.get(forcedRedirects, 'qualityTerminate', '')
		default:
			throw new Error(`Unknown END_STUDY subtype: ${subtype}`)
	}
}

const PanelRedirects = props => {
	const intl = useIntl()

	const formatUrl = panel => {
		const url = getPanelRedirectBySubtype(props.subtype, panel.forcedRedirects)

		if (_.isNil(url) === true || url.length === 0) {
			return intl.formatMessage({ id: 'no_redirect' })
		}

		if (
			props.isUserInternalEmployee === true ||
			panel.provider.providerType === PROVIDER_TYPE.DEFAULT_PROVIDER
		) {
			return url.replace(URL_VALIDATION_SKIP_PREFIX, '')
		}

		return intl.formatMessage({ id: 'panel_provider_redirect' })
	}

	const renderPanelRedirect = (panel, index) => {
		const url = formatUrl(panel)

		return (
			<div data-testid={`panel-redirect-${panel.label}`} key={`panel-redirect-${index}`}>
				<div className={classes.label}>{panel.label}</div>
				<div title={url} className={classes.url}>
					{url}
				</div>
			</div>
		)
	}

	return (
		<div data-testid="panel-redirects" className={classes.wrapper}>
			{filterOutGsPreviewPanel(props.respondentSources).map(renderPanelRedirect)}
		</div>
	)
}

PanelRedirects.propTypes = {
	isUserInternalEmployee: PropTypes.bool.isRequired,
	respondentSources: PropTypes.arrayOf(panelShape).isRequired,
	subtype: PropTypes.string.isRequired,
}

export default PanelRedirects
