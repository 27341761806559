export const VISUAL_FLOW_MAX_NESTING_LIMIT = 20

export const CHOICE_SUBTYPE = {
	CHECKBOX: 'CHECKBOX',
	IMAGE: 'IMAGE',
	SELECT: 'SELECT',
}

export const ALLOCATION_SUBTYPE = {
	INPUT: 'INPUT',
}

export const RANKING_SUBTYPE = {
	RANKING_DRAG_AND_DROP: 'RANKING_DRAG_AND_DROP',
	RANKING_SELECT: 'RANKING_SELECT',
}

export const ALLOCATION_LIMIT_TYPES = {
	VARIABLE: 'VARIABLE',
	FREE_TEXT_ANSWER: 'FREE_TEXT_ANSWER',
	VALUE: 'VALUE',
}

export const LIST_FILTER_RULE_TYPES = {
	ALLOCATION_QUESTION: 'ALLOCATION_QUESTION',
	COMMUNITY: 'COMMUNITY',
	ITEM_ATTRIBUTE: 'ITEM_ATTRIBUTE',
	MATRIX: 'MATRIX',
	RANKING_QUESTION: 'RANKING_QUESTION',
	QUESTION: 'QUESTION',
}

export const LIST_ALLOCATION_FILTER_FUNCTIONS = {
	MAX: 'MAX',
	MIN: 'MIN',
}

export const LIST_INPUT_TYPE = {
	FILE: 'FILE',
	LIST: 'LIST',
}

export const VISUAL_FLOW_MODULE_TYPES = {
	ALLOCATION: 'ALLOCATION',
	A_CHOICE: 'A_CHOICE',
	A_END_STUDY: 'A_END_STUDY',
	A_FREE_TEXT: 'A_FREE_TEXT',
	A_MESSAGE: 'A_MESSAGE',
	A_OEQ: 'A_OEQ',
	A_SNIPPET: 'A_SNIPPET',
	HEATMAP: 'HEATMAP',
	LIST: 'LIST',
	MATRIX_CHOICE: 'MATRIX_CHOICE',
	MAXDIFF: 'MAXDIFF',
	RANKING: 'RANKING',
	SET_VARIABLE: 'SET_VARIABLE',
	UI_COMMAND: 'UI_COMMAND',
}

export const PROFLOW_TYPES = {
	ALLOCATION: 'ALLOCATION',
	A_CHOICE: 'A_CHOICE',
	A_END_STUDY: 'A_END_STUDY',
	A_EVALUATOR: 'A_EVALUATOR',
	A_FREE_TEXT: 'A_FREE_TEXT',
	A_MESSAGE: 'A_MESSAGE',
	A_OEQ: 'A_OEQ',
	HEATMAP: 'HEATMAP',
	MAXDIFF: 'MAXDIFF',
	RANKING: 'RANKING',
	RESEARCH_LABELING: 'RESEARCH_LABELING',
	UI_COMMAND: 'UI_COMMAND',
}

export const BRANCH_COLORS_MAP = {
	'#f15a24': 'orange',
	'#66318f': 'purple',
	'#694a2b': 'brown',
	'#00a99d': 'teal',
	'#007f76': 'darkteal',
	'#0b9ed5': 'gs-primary',
}

export const BRANCH_COLORS = ['#f15a24', '#66318f', '#694a2b', '#00a99d', '#007f76']

export const VISUAL_FLOW_WELCOME_SCREEN = 'welcome-screen'

export const ADD_MODULE_TYPES = {
	BRANCH_END: 'BRANCH_END',
	COPY: 'COPY',
	MODULE: 'MODULE',
}

export const VISUAL_FLOW_INVALID_MODULE_TYPES = {
	choice_emptyOptions: 'choice_emptyOptions',
	choice_noImage: 'choice_noImage',
	choice_nonUniqueOptions: 'choice_nonUniqueOptions',
	choice_nonUniqueOptionIds: 'choice_nonUniqueOptionIds',
	choice_invalidOptionId: 'choice_invalidOptionId',
	choice_missingOptionCode: 'choice_missingOptionCode',
	choice_nonUniqueSimpleNames: 'choice_nonUniqueSimpleNames',
	choice_invalidFilter: 'choice_invalidFilter',
	choice_invalidTrapQuestionSettings: 'choice_invalidTrapQuestionSettings',
	choice_incomplete_dynamic_options_settings: 'choice_incomplete_dynamic_options_settings',
	choice_invalid_list_selected: 'choice_invalid_list_selected',
	choice_invalid_list_column_selected: 'choice_invalid_list_column_selected',
	condition_hasInvalidSelection: 'condition_hasInvalidSelection',
	condition_invalidAllocationOrRankingInput: 'condition_invalidAllocationOrRankingInput',
	condition_invalidAllocationOrRankingRange: 'condition_invalidAllocationOrRankingRange',
	condition_invalidAnyOfValidSelection: 'condition_invalidAnyOfValidSelection',
	condition_invalidDateInput: 'condition_invalidDateInput',
	condition_invalidFreetextInput: 'condition_invalidFreetextInput',
	condition_invalid_follow_up: 'condition_invalid_follow_up',
	condition_noChoicesPresent: 'condition_noChoicesPresent',
	condition_noOptionsSelected: 'condition_noOptionsSelected',
	condition_oneOfOptionsMissed: 'condition_oneOfOptionsMissed',
	condition_studyObjectMissed: 'condition_studyObjectMissed',
	condition_tooFewOptions: 'condition_tooFewOptions',
	condition_tooManyOptions: 'condition_tooManyOptions',
	dataset_nonUniqueSimpleName: 'dataset_nonUniqueSimpleName',
	dataset_requiredSimpleName: 'dataset_requiredSimpleName',
	dataset_simpleNametooLong: 'dataset_simpleNameTooLong',
	dataset_shortNameContainsDot: 'dataset_shortNameContainsDot',
	endStudy_emptyUrl: 'endStudy_emptyUrl',
	endStudy_invalid_redirect_label: 'endStudy_invalid_redirect_label',
	snippet_invalid_code: 'snippet_invalid_code',
	snippet_invalid_next_step: 'snippet_invalid_next_step',
	snippet_invalid_quota_condition: 'snippet_invalid_quota_condition',
	message_timeoutMinTimeout: 'message_timeoutMinTimeout',
	message_text_too_long: 'message_text_too_long',
	message_media_missing_alt: 'message_media_missing_alt',
	block_invalid_children: 'block_invalid_children',
	randomizer_empty: 'randomizer_empty',
	loop_empty: 'loop_empty',
	dataset_invalid: 'dataset_invalid',
	set_variable_empty_arg: 'set_variable_empty_arg',
	set_variable_invalid_variable_name: 'set_variable_invalid_variable_name',
	set_variable_invalid_json_argument: 'set_variable_invalid_json_argument',
	oeq_preseeded_statement_too_short: 'oeq_preseeded_statement_too_short',
	oeq_invalid_elaborate_settings: 'oeq_invalid_elaborate_settings',
	matrix_choice_nonUniqueShortNames: 'matrix_choice_nonUniqueShortNames',
	matrix_choice_invalid_list_selected: 'matrix_choice_invalid_list_selected',
	matrix_choice_invalid_list_column_selected: 'matrix_choice_invalid_list_column_selected',
	invalid_module_filter: 'invalid_module_filter',
	allocation_invalid_limit: 'allocation_invalid_limit',
	allocation_invalid_range_min: 'allocation_invalid_range_min',
	allocation_invalid_range: 'allocation_invalid_range',
	allocation_invalid_range_limit: 'allocation_invalid_range_limit',
	allocation_option_default_invalid: 'allocation_option_default_invalid',
	allocation_option_default_out_of_range: 'allocation_option_default_out_of_range',
	allocation_invalid_filter: 'allocation_invalid_filter',
	list_filter_name_empty: 'list_filter_name_empty',
	list_filter_invalid_selected: 'list_filter_invalid_selected',
	list_filter_invalid_matrix_rule: 'list_filter_invalid_matrix_rule',
	list_filter_invalid_community_rule: 'list_filter_invalid_community_rule',
	list_filter_invalid_question_rule: 'list_filter_invalid_question_rule',
	list_filter_invalid_allocation_question_rule: 'list_filter_invalid_allocation_question_rule',
	list_filter_invalid_item_attribute_rule: 'list_filter_invalid_item_attribute_rule',
	list_empty: 'list_empty',
	list_input_type_empty: 'list_input_type_empty',
	max_diff_too_few_options: 'max_diff_too_few_options',
	max_diff_too_few_items_per_round: 'max_diff_too_few_items_per_round',
	heatmap_no_image: 'heatmap_no_image',
	heatmap_max_timer_error: 'heatmap_max_timer_error',
	heatmap_min_max_answers_error: 'heatmap_min_max_answers_error',
	heatmap_min_answers_error: 'heatmap_min_answers_error',
	heatmap_max_answers_error: 'heatmap_max_answers_error',
}

export const VISUAL_FLOW_REMOVE_TYPES = {
	REMOVE_ALL: 'removeAll',
	REMOVE_BRANCH: 'removeBranch',
	KEEP_ALL: 'keepAll',
	SOFT_REMOVE_ALL: 'softRemoveAll',
}

export const CHOICE_OPTION_ORDER_MODE = {
	QUESTION_ORDER: 'QUESTION_ORDER',
	INPUT_LIST_ORDER: 'INPUT_LIST_ORDER',
}
