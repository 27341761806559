import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import { Formik, Form } from 'formik'
import { useIntl } from 'react-intl'
import _ from 'lodash'

import getAllChoiceOptionsRandomized from 'helpers/visualFlowModules/getAllChoiceOptionsRandomized'
import setChoiceOptionsIsRandomized from 'helpers/visualFlowModules/setChoiceOptionsIsRandomized'
import { getNormalOptionsCount } from 'helpers/visualFlowModules/getNormalOptionsCount'

import AutoSubmit from 'components/_formik/_complex/AutoSubmit'

import ChoiceOptions from 'components/_formik/_custom/_studyDesign/ChoiceOptions'
import DatasetShortName from 'components/_formik/_custom/_studyDesign/DatasetShortName'
import FormHolder from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/FormHolder'
import Header from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/Header'
import ModuleAdvancedOptionsToggle from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/ModuleAdvancedOptionsToggle'
import OptionsFilter from 'components/_formik/_custom/_studyDesign/OptionsFilter'
import { ALLOCATION_LIMIT_TYPES, VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

import {
	areAllocationLimitAndMaxRangeValid,
	areAllocationLimitAndMinRangeValid,
	isAllocationLimitInputValid,
	isAllocationLimitValueValid,
	isAllocationRangeMinValid,
	isAllocationRangeValid,
} from 'routes/_study/StudyDesign/_store/helpers/flowValidation/validateModule/validateAllocation'

import AllocationHintValueSettings from './_components/AllocationHintValueSettings'
import AllocationLimit from './_components/AllocationLimit'
import AllocationRange from './_components/AllocationRange'

import classes from './Allocation.module.scss'

const createExpandedState = (options, isExpanded) => {
	const state = {}
	options.forEach(option => {
		state[option.id] = isExpanded
	})

	return state
}

const getAreAllCollapsed = state => JSON.stringify(state).includes('true') === false

const getAreAllRandomized = getAllChoiceOptionsRandomized

const Allocation = props => {
	const intl = useIntl()
	const [expandedState, setExpandedState] = useState(
		createExpandedState(props.initialValues.options, false),
	)
	const [isRangeDisplayed, setIsRangeDisplayed] = useState(false)
	const [isLimitDisplayed, setIsLimitDisplayed] = useState(false)
	const [isHintSettingsDisplayed, setIsHintSettingsDisplayed] = useState(false)

	const toggleIsRangeDisplayed = () => {
		setIsRangeDisplayed(current => current !== true)
	}

	const toggleIsLimitDisplayed = () => {
		setIsLimitDisplayed(current => current !== true)
	}

	const toggleIsHintSettingsDisplayed = () => {
		setIsHintSettingsDisplayed(current => current !== true)
	}

	const toggleOption = idOption => {
		setExpandedState(current => ({
			...current,
			[idOption]: current[idOption] !== true,
		}))
	}

	const expandOption = idOption => {
		setExpandedState(current => ({
			...current,
			[idOption]: true,
		}))
	}

	const toggleAll = () => {
		setExpandedState(current => {
			const newValue = getAreAllCollapsed(current)

			return createExpandedState(props.initialValues.options, newValue)
		})
	}

	return (
		<Formik initialValues={{ ...props.initialValues }} onSubmit={() => {}}>
			{({ setFieldValue, setValues, values }) => {
				const setRandomizeForAllOptions = value => {
					setValues({
						...values,
						options: setChoiceOptionsIsRandomized(values.options, value),
					})
				}
				const randomizeAll = () => setRandomizeForAllOptions(true)
				const pinAll = () => setRandomizeForAllOptions(false)

				const regenerateCodeValues = () => {
					setValues({
						...values,
						options: values.options.map((o, i) => ({ ...o, code: i + 1 })),
					})
				}

				const additionalActions = [
					{
						isDisabled: false,
						label:
							getAreAllCollapsed(expandedState) === false
								? intl.formatMessage({ id: 'choice.detail.collapse_all' })
								: intl.formatMessage({ id: 'choice.detail.expand_all' }),
						tooltipText: '',
						value: toggleAll,
					},
				]

				if (props.disabled === false) {
					additionalActions.push(
						{
							isDisabled: false,
							label:
								getAreAllRandomized(values.options) === true
									? intl.formatMessage({ id: 'choice.detail.pin_options' })
									: intl.formatMessage({ id: 'choice.detail.randomize_all' }),
							tooltipText: '',
							value: getAreAllRandomized(values.options) === true ? pinAll : randomizeAll,
						},
						{
							isDisabled: false,
							label: intl.formatMessage({ id: 'choice.detail.reset_code_values' }),
							tooltipText: '',
							value: regenerateCodeValues,
						},
					)
				}

				const isLimitBasedOnFreetextValid =
					values.limit.value.type !== ALLOCATION_LIMIT_TYPES.FREE_TEXT_ANSWER ||
					props.availabableFreeTextModules.some(
						module => module.definition.id === values.limit.value.value,
					)

				const isLimitInvalid =
					isAllocationLimitValueValid({ definition: values }) === false ||
					isAllocationLimitInputValid({ definition: values }) === false ||
					isLimitBasedOnFreetextValid === false

				const isRangeInvalid =
					isAllocationRangeMinValid({ definition: values }) === false ||
					isAllocationRangeValid({ definition: values }) === false

				return (
					<Fragment>
						<Header
							additionalActions={additionalActions}
							closeModuleDetail={props.closeModuleDetail}
							copyModule={props.copyModule}
							copyModuleButtonParams={props.copyModuleButtonParams}
							disabled={props.disabled}
							generalDefinition={props.generalDefinition}
							isFlowChanged={props.isFlowChanged}
							isRelatedModuleInvalid={props.isRelatedModuleInvalid}
							moduleDefinition={values}
							openCopyToLibraryForm={props.openCopyToLibraryForm}
							showCopyToLibrary
						/>
						<FormHolder>
							<AutoSubmit
								values={values}
								onSave={props.saveModule}
								formComponent={() => (
									<Form>
										<DatasetShortName
											disabled={props.disabled}
											errorInvalidSimpleName={props.errorInvalidSimpleName}
											errorNonUniqueSimpleName={props.errorNonUniqueSimpleName}
											values={values}
										/>
										<ModuleAdvancedOptionsToggle
											id="limit-options-toggle"
											isExpanded={isLimitDisplayed}
											label={intl.formatMessage({ id: 'allocation.detail.limit.label' })}
											onClick={toggleIsLimitDisplayed}
											isInvalid={isLimitInvalid}
										/>
										{isLimitDisplayed === true && (
											<div className={classes.settings}>
												<AllocationLimit
													availabableFreeTextModules={props.availabableFreeTextModules}
													disabled={props.disabled}
													isUserInternalEmployee={props.isUserInternalEmployee}
													setFieldValue={setFieldValue}
													values={values}
												/>
											</div>
										)}
										{areAllocationLimitAndMaxRangeValid({ definition: values }) === false && (
											<span className="title-error">
												{intl.formatMessage(
													{
														id: 'allocation.detail.max.range.limit.invalid',
													},
													{
														points: getNormalOptionsCount(values.options) * values.range.max,
													},
												)}
											</span>
										)}
										{areAllocationLimitAndMinRangeValid({ definition: values }) === false && (
											<span className="title-error">
												{intl.formatMessage(
													{
														id: 'allocation.detail.min.range.limit.invalid',
													},
													{
														points: getNormalOptionsCount(values.options) * values.range.min,
													},
												)}
											</span>
										)}
										<ModuleAdvancedOptionsToggle
											id="range-options-toggle"
											isExpanded={isRangeDisplayed}
											label={intl.formatMessage({ id: 'allocation.detail.range.label' })}
											onClick={toggleIsRangeDisplayed}
											isInvalid={isRangeInvalid}
										/>
										{isRangeDisplayed === true && (
											<div className={classes.settings}>
												<AllocationRange
													disabled={props.disabled}
													setValues={setValues}
													values={values}
												/>
											</div>
										)}
										<ModuleAdvancedOptionsToggle
											id="hint-options-toggle"
											isExpanded={isHintSettingsDisplayed}
											label={intl.formatMessage({ id: 'allocation.detail.hint.label' })}
											onClick={toggleIsHintSettingsDisplayed}
										/>
										{isHintSettingsDisplayed === true && (
											<div className={classes.settings}>
												<AllocationHintValueSettings disabled={props.disabled} />
											</div>
										)}
										<ChoiceOptions
											disabled={props.disabled}
											expandOption={expandOption}
											getNormalOptionsCount={getNormalOptionsCount}
											isUnsaved={props.isUnsaved}
											isUserInternalEmployee={props.isUserInternalEmployee}
											listState={expandedState}
											moduleType={VISUAL_FLOW_MODULE_TYPES.ALLOCATION}
											openMediaManager={_.noop}
											regenerateCodeValues={regenerateCodeValues}
											setFieldValue={setFieldValue}
											setValues={setValues}
											showNoneOfTheseOption
											toggleOption={toggleOption}
											values={values}
											upperLists={props.upperLists}
										/>
										<OptionsFilter
											disabled={props.disabled}
											isUserInternalEmployee={props.isUserInternalEmployee}
											moduleType={VISUAL_FLOW_MODULE_TYPES.ALLOCATION}
											values={values}
										/>
									</Form>
								)}
							/>
						</FormHolder>
					</Fragment>
				)
			}}
		</Formik>
	)
}

Allocation.propTypes = {
	availabableFreeTextModules: PropTypes.array.isRequired,
	closeModuleDetail: PropTypes.func.isRequired,
	copyModule: PropTypes.func.isRequired,
	copyModuleButtonParams: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
	errorInvalidSimpleName: PropTypes.bool.isRequired,
	errorNonUniqueSimpleName: PropTypes.bool.isRequired,
	generalDefinition: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	isFlowChanged: PropTypes.bool.isRequired,
	isRelatedModuleInvalid: PropTypes.bool.isRequired,
	isUnsaved: PropTypes.bool,
	isUserInternalEmployee: PropTypes.bool.isRequired,
	openCopyToLibraryForm: PropTypes.func.isRequired,
	saveModule: PropTypes.func.isRequired,
	upperLists: PropTypes.array.isRequired,
}

export default Allocation
