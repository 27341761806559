import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Formik, Form, Field, FieldArray } from 'formik'
import { useIntl } from 'react-intl'

import { snippetTypeOptions, PROFLOW_DEFINITIONS } from './constants'
import { validate, catchJSONError } from 'helpers/visualFlowModules/snippet'

import classes from './Snippet.module.scss'

import AutoSubmit from 'components/_formik/_complex/AutoSubmit'
import Label from 'components/_formik/_base/Label'
import Select from 'components/_formik/_base/Select'
import Textarea from 'components/_formik/_base/Textarea'
import Quota from 'components/_formik/_custom/_studyDesign/Quota'

import CodeErrors from 'components/_scaffolding/CodeErrors'
import CodeLabel from 'components/_scaffolding/CodeLabel'

import Header from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/Header'
import FormHolder from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/FormHolder'

import Description from './_components/Description'
import NextStep from './_components/NextStep'

const formatCode = (code, values, setValues) => {
	let formattedCode = code
	try {
		formattedCode = JSON.stringify(JSON.parse(code), null, 2)
	} catch (e) {
		setValues({
			...values,
			...catchJSONError(e),
		})
	}
	return formattedCode
}

const Snippet = props => {
	const intl = useIntl()

	return (
		<Formik initialValues={{ ...props.initialValues }} onSubmit={() => {}}>
			{({ values, setFieldValue, setValues }) => (
				<Fragment>
					<Header
						closeModuleDetail={props.closeModuleDetail}
						generalDefinition={props.generalDefinition}
						disabled={props.disabled}
						moduleDefinition={values}
						isFlowChanged={props.isFlowChanged}
						openCopyToLibraryForm={props.openCopyToLibraryForm}
						showCopyToLibrary
					/>
					<FormHolder>
						<AutoSubmit
							values={values}
							onSave={props.saveModule}
							formComponent={() => {
								if (props.isUserInternalEmployee === false) {
									return (
										<Form>
											<div className={classes['section-name-label']}>Functionality description</div>
											<Description
												disabled={props.disabled}
												errorInvalidSimpleName={props.errorInvalidSimpleName}
												errorNonUniqueSimpleName={props.errorNonUniqueSimpleName}
												isUserInternalEmployee={props.isUserInternalEmployee}
												values={values}
											/>
										</Form>
									)
								}

								return (
									<Form>
										<Label label={intl.formatMessage({ id: 'type' })} />
										<Field
											component={Select}
											disabled={props.disabled}
											name="type"
											options={snippetTypeOptions}
											onChange={value => {
												setValues({
													...values,
													code: PROFLOW_DEFINITIONS[value],
													type: value,
													...validate(value, PROFLOW_DEFINITIONS[value], [], []),
												})
											}}
										/>
										<CodeLabel
											label={intl.formatMessage({ id: 'snippet.code_validator' })}
											isCodeValid={values.isCodeValid}
										/>
										<Field
											component={Textarea}
											disabled={props.disabled}
											className={classes.code}
											spellCheck="false"
											name="code"
											type="text"
											value={
												values.isCodeValid === true
													? formatCode(values.code, values, setValues)
													: values.code
											}
											onBlur={event => {
												const codeString = event.currentTarget.value
												setValues({
													...values,
													...validate(values.type, codeString, values.nextSteps, values.quotas),
												})
											}}
										/>
										<CodeErrors root="Code" errors={values.codeErrors} />
										{values.quotas.length > 0 && (
											<FieldArray name="">
												{() =>
													values.quotas.map((quota, i) => (
														<Quota
															disabled={props.disabled}
															fieldNamePrefix={`quotas[${i}]`}
															idStudy={props.idStudy}
															key={`quota-${i}`}
															quota={quota}
															setFieldValue={setFieldValue}
														/>
													))
												}
											</FieldArray>
										)}
										<div className={classes['section-name-label']}>Functionality description</div>
										<Description
											disabled={props.disabled}
											errorInvalidSimpleName={props.errorInvalidSimpleName}
											errorNonUniqueSimpleName={props.errorNonUniqueSimpleName}
											isUserInternalEmployee={props.isUserInternalEmployee}
											values={values}
										/>
										{values.nextSteps.length > 0 && (
											<FieldArray name="">
												{() =>
													values.nextSteps.map((nextStep, i) => (
														<NextStep
															disabled={props.disabled}
															index={i}
															key={`nextStep-${i}`}
															modules={props.modules}
															nextStep={nextStep}
														/>
													))
												}
											</FieldArray>
										)}
									</Form>
								)
							}}
						/>
					</FormHolder>
				</Fragment>
			)}
		</Formik>
	)
}

Snippet.propTypes = {
	closeModuleDetail: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
	errorInvalidSimpleName: PropTypes.bool.isRequired,
	errorNonUniqueSimpleName: PropTypes.bool.isRequired,
	generalDefinition: PropTypes.object.isRequired,
	idStudy: PropTypes.string.isRequired,
	initialValues: PropTypes.object.isRequired,
	isUserInternalEmployee: PropTypes.bool.isRequired,
	modules: PropTypes.object.isRequired,
	saveModule: PropTypes.func.isRequired,
	isFlowChanged: PropTypes.bool.isRequired,
	openCopyToLibraryForm: PropTypes.func.isRequired,
}

export default Snippet
