import { gql } from 'graphql-tag'
import { getIsInPresentationSharePath } from 'helpers/router'

const isInAuthorizedMode = getIsInPresentationSharePath() === false

const flow = gql`
	fragment flow on Study {
		flow {
			activeFlow
			proFlow
			visualFlow
			stats
		}
	}
`

const respondents = gql`
	fragment respondents on Study {
		respondents {
			completed
			dropped
			requested
			overquota
			terminated
			qualityTerminated
		}
	}
`

const studyStatus = gql`
	fragment studyStatus on Study {
		state
		label
		version
		${isInAuthorizedMode === true ? 'tags' : ''}
	}
`

const dataImport = gql`
	fragment dataImport on DataImport {
		fileKey
		filename
		idDataImport
		importedModules
		isCommunity
		isComplete
		respondentKey
		rules {
			column
			hasEmptyRows
			hasStringValues
			hasUniqueValues
			idDataImportRule
			importAs
			isActive
			moduleType
		}
		uniqueKey
	}
`

const finishedStatistics = gql`
	fragment finishedStatistics on StudyStatistics {
		lastRecalculation
		firstComplete
		lastComplete
		LOI {
			average
			percentile01
			percentile05
			percentile95
			percentile99
			median
		}
		engagement
	}
`

const statistics = gql`
	fragment statistics on StudyStatistics {
		activeSolvers
		...finishedStatistics
	}
	${finishedStatistics}
`

const dataDownload = gql`
	fragment dataDownload on DataDownload {
		downloads {
			idDownload
			label
			status
			startedTime
			requestedTime
			completedTime
			platformFile {
				idPlatformFile
				size
				url
				label
			}
			requests {
				title
				type
				filters {
					idQuestions
				}
				options {
					value
					key
				}
				format
			}
		}
	}
`

const quotaStatistics = gql`
	fragment quotaStatistics on QuotaStatistics {
		agName
		collectedRespondents
		${isInAuthorizedMode === true ? 'condition' : ''}
		idQuotaStatistics
		isPercentage
		requested
	}
`

const note = gql`
	fragment note on StudyNote {
		idNote
		idStudy
		idItem
		idParentNote
		type
		text
		task {
			isTask
			isDone
		}
		meta {
			created
			updated
		}
		userInfo {
			idUserInfo
			idUser
			displayName
			image {
				id
				url
			}
		}
	}
`

const limits = gql`
	fragment limits on Study {
		limits {
			study {
				respondents
				previewRespondents
				segments
			}
			flow {
				visualModules
				datasets
				oeq
				quotas
			}
		}
	}
`

const quantQualSlide = gql`
	fragment quantQualSlide on QuantQualSlide {
		idQuantQualSlide
		idSlide
		idStudy
		meta {
			created
			updated
		}
		settings {
			title
			idAIOpenEndQuestion
			idChoiceQuestion
			idsSegments
			answersLimit
			sorted
			filtered
			excludedAnswers
			nFilterValue
			highlightSignificantDatapoints
			themeViewSettings {
				isActive
				showUnthemedAnswers
			}
		}
	}
`

export {
	dataDownload,
	dataImport,
	finishedStatistics,
	flow,
	limits,
	note,
	quantQualSlide,
	quotaStatistics,
	respondents,
	statistics,
	studyStatus,
}
