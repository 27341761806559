import { enTranslator as intl } from 'intl.js'

import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import { CHOICE_OPTION } from './helpers/CHOICE_OPTION'
import {
	CHOICE_SUBTYPE,
	VISUAL_FLOW_MODULE_TYPES,
	CHOICE_OPTION_ORDER_MODE,
} from 'constants/studyDesign'
import { GET_QUESTION_MESSAGE } from './MESSAGE'

import Choice from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/Choice'

const getShortName = (subtype, modules) => {
	const title =
		subtype === CHOICE_SUBTYPE.IMAGE
			? intl.formatMessage({ id: 'imageChoice.title' })
			: intl.formatMessage({ id: 'choice.title' })
	return getDatasetShortName(modules, title, VISUAL_FLOW_MODULE_TYPES.A_CHOICE, subtype)
}

export const getDefaultDynamicOptionsSettings = () => ({
	isActive: false,
	idMainList: null,
	idInputList: null,
	optionLabelColumn: null,
	optionsOrderMode: CHOICE_OPTION_ORDER_MODE.QUESTION_ORDER,
})

const CHOICE_DEFINITION = (subtype, modules, additionalProperties) => ({
	options: [
		CHOICE_OPTION(
			subtype,
			false,
			1,
			intl.formatMessage({ id: 'choice.option.default_label' }, { index: 1 }),
			false,
		),
		CHOICE_OPTION(
			subtype,
			false,
			2,
			intl.formatMessage({ id: 'choice.option.default_label' }, { index: 2 }),
			false,
		),
	],
	idRelatedMessage: null,
	shortName: getShortName(subtype, modules),
	filter: null,
	subtype,
	mandatory: true,
	multiple: false,
	minSelection: 1,
	maxSelection: 1,
	trapQuestionSettings: {
		isTrapQuestion: false,
		selection: 'equalTo',
		correctAnswers: [],
	},
	messages: [GET_QUESTION_MESSAGE()],
	dynamicOptionsSettings: getDefaultDynamicOptionsSettings(),
})

export default subtype => ({
	type: VISUAL_FLOW_MODULE_TYPES.A_CHOICE, // MODULE_DEFINITIONS.A_CHOICE.type,
	style: subtype === CHOICE_SUBTYPE.CHECKBOX ? 'choice' : 'image-choice',
	title: definition =>
		definition.subtype === CHOICE_SUBTYPE.IMAGE ? 'imageChoice.title' : 'choice.title',
	description: definition =>
		definition.subtype === CHOICE_SUBTYPE.IMAGE
			? 'imageChoice.dataset.description'
			: 'choice.dataset.description',
	component: Choice,
	generator: (modules, additionalProperties) =>
		CHOICE_DEFINITION(subtype, modules, additionalProperties),
})
