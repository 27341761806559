import color from 'color'
import { REPORT_BUILDER_COLORS } from 'constants/reportBuilderColors'
import { REPORT_TYPES } from 'constants/reports'

const getColorModifiers = barCount => {
	if (barCount < 3) {
		return [0, 0.3]
	}

	if (barCount < 4) {
		return [0, 0.3, -0.1]
	}

	return [0, 0.3, -0.1, 0.2]
}

export const getOpenAnswerCrosstabFill = (barIndex, fill, barCount) => {
	const colorObject = color(fill)

	const modifiers = getColorModifiers(barCount)
	const modifierIndex = barIndex % modifiers.length
	const modifier = modifiers[modifierIndex]

	const result = colorObject.lighten(modifier)
	return result.hex()
}

export const getChoiceAnswersCrosstabFill = segmentIndex => {
	const segmentColorIndex = segmentIndex % REPORT_BUILDER_COLORS.length
	return REPORT_BUILDER_COLORS[segmentColorIndex]
}

export const getCrosstabFill = (reportType, barIndex, fill, barCount) => {
	if (reportType === REPORT_TYPES.HEATMAP) {
		throw new Error('Heatmap does not support crosstab!')
	}

	if (
		reportType === REPORT_TYPES.ALLOCATION_ANSWERS ||
		reportType === REPORT_TYPES.CHOICE_ANSWERS ||
		reportType === REPORT_TYPES.MAXDIFF ||
		reportType === REPORT_TYPES.RANKING_ANSWERS
	) {
		return getChoiceAnswersCrosstabFill(barIndex)
	}

	return getOpenAnswerCrosstabFill(barIndex, fill, barCount)
}
