import _ from 'lodash'

import { tools } from 'constants/tools'

import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { getDecimalPointNumberFromLeadingZeroNumber } from 'helpers/reportBuilder/getDecimalPointNumberFromLeadingZeroNumber'
import { getHasThemesData } from 'helpers/reportBuilder/getHasThemesData'
import { getLetterAndThemeParams } from 'helpers/reportBuilder/getLetterAndThemeParams'
import { getShouldUseThemes } from 'helpers/reportBuilder/getShouldUseThemes'
import { getStatementThemeName } from 'helpers/statementThemes'
import { hasBlobData } from 'helpers/reportBuilder/hasBlobData'

import {
	getSegmentTotalSupportPercentageKey,
	getSegmentTotalSupportCompletesKey,
	getSegmentTotalSupportLowerBoundKey,
	getSegmentTotalSupportUpperBoundKey,
	getSegmentConfidenceIntervalKey,
	getSegmentAgreeCountKey,
	getSegmentDisagreeCountKey,
	getSegmentIndifferentCountKey,
	getSegmentTotalSupportDecimalPercentageKey,
	getSegmentConfidenceIntervalDecimalKey,
} from 'store/reportBuilder'
import { REPORT_TYPES, REPORT_BLOB_TYPES, SUPPORT_KEYS } from 'constants/reports'

/**
 * Munge Functions
 */
const mungeIdeas = (ideas, idsSegments) => {
	return ideas.map(idea => {
		const percentAndCompletes = _.flatten(
			idsSegments.map(idSegment => {
				const percent = idea[getSegmentTotalSupportDecimalPercentageKey(idSegment)]
				const confidence = idea[getSegmentConfidenceIntervalDecimalKey(idSegment)]
				const agreeCount = idea[getSegmentAgreeCountKey(idSegment)]
				const disagreeCount = idea[getSegmentDisagreeCountKey(idSegment)]
				const indifferentCount = idea[getSegmentIndifferentCountKey(idSegment)]

				return [agreeCount, indifferentCount, disagreeCount, percent, confidence]
			}),
		)

		const themeName = getStatementThemeName(idea, true)

		return {
			..._.pick(
				idea,
				_.flatMap(idsSegments, idSegment => [
					getSegmentTotalSupportPercentageKey(idSegment),
					getSegmentTotalSupportCompletesKey(idSegment),
					getSegmentTotalSupportLowerBoundKey(idSegment),
					getSegmentTotalSupportUpperBoundKey(idSegment),
					getSegmentConfidenceIntervalKey(idSegment),
					getSegmentAgreeCountKey(idSegment),
					getSegmentDisagreeCountKey(idSegment),
					getSegmentIndifferentCountKey(idSegment),
				]),
			),
			name: idea.label,
			fill: idea.color,
			themeName,
			id: idea.idStatement,
			letter: idea.letter,
			shown: true,
			unit: '%',
			toExport: () => [idea.letter, idea.label, ...percentAndCompletes, themeName || ''],
		}
	})
}

const mungeThemes = (themes, idsSegments) => {
	return themes.map(theme => {
		const percentAndCompletes = _.flatten(
			idsSegments.map(idSegment => {
				const percent = theme[getSegmentTotalSupportDecimalPercentageKey(idSegment)]

				return [percent]
			}),
		)

		const themeName = getStatementThemeName(theme, true)

		return {
			..._.pick(
				theme,
				_.flatMap(idsSegments, idSegment => [
					getSegmentTotalSupportPercentageKey(idSegment),
					getSegmentTotalSupportCompletesKey(idSegment),
					getSegmentTotalSupportLowerBoundKey(idSegment),
					getSegmentTotalSupportUpperBoundKey(idSegment),
				]),
			),
			answersCount: theme.statements.length,
			name: themeName,
			fill: theme.color,
			themeName: null, // theme does not have theme
			id: theme.idStatementTheme,
			letter: theme.letter,
			shown: true,
			unit: '%',
			toExport: () => [theme.letter, themeName, theme.statements.length, ...percentAndCompletes],
		}
	})
}

/**
 * Helpers
 */
const calculateThemeIdeas = (
	idStudy,
	blobData,
	totalSegmentData,
	studyObjectData,
	slideSettings,
) => {
	if (slideSettings === undefined) return []

	if (studyObjectData === undefined) {
		return []
	}

	const isThemesSupportDefined = getHasThemesData(studyObjectData.statementThemes, totalSegmentData)

	if (isThemesSupportDefined === false) {
		return []
	}

	const { idStudyObject, idsSegments } = slideSettings

	return studyObjectData.statementThemes.map(themeDescription => {
		const themeSupport = totalSegmentData.themeSupport[themeDescription.idStatementTheme]

		const theme = {
			...themeDescription,
			...themeSupport,
		}

		idsSegments.forEach(idSegment => {
			const segmentData =
				blobData[
					getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT, idSegment)
				]

			const segmentStatementData = segmentData.themeSupport[theme.idStatementTheme]

			const lowerBound = Math.round(segmentStatementData.supportStrength.lowerBound * 100)
			const upperBound = Math.round(segmentStatementData.supportStrength.upperBound * 100)
			const percentage = Math.round(segmentStatementData.expectedSupport * 100)
			const percentageDecimal = getDecimalPointNumberFromLeadingZeroNumber(
				segmentStatementData.expectedSupport,
			)
			const count = percentage

			theme[getSegmentTotalSupportDecimalPercentageKey(idSegment)] = Number(percentageDecimal)
			theme[getSegmentTotalSupportPercentageKey(idSegment)] = Number(percentage)
			theme[getSegmentTotalSupportCompletesKey(idSegment)] = Number(count)
			theme[getSegmentTotalSupportLowerBoundKey(idSegment)] = Number(lowerBound)
			theme[getSegmentTotalSupportUpperBoundKey(idSegment)] = Number(upperBound)
		})

		return theme
	})
}

const calculateStatementIdeas = (
	idStudy,
	blobData,
	totalSegmentData,
	studyObjectData,
	slideSettings,
) => {
	if (slideSettings === undefined) return []

	if (studyObjectData === undefined) {
		return []
	}

	const { idStudyObject, idsSegments } = slideSettings

	return totalSegmentData.statementSupports.map(statement => {
		const idea = getLetterAndThemeParams(
			statement,
			statement,
			studyObjectData.statementThemes,
			tools.TOTAL_SEGMENT_UUID,
			totalSegmentData.statementSupports,
			getShouldUseThemes(slideSettings),
		)

		idsSegments.forEach(idSegment => {
			const segmentData =
				blobData[
					getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT, idSegment)
				]

			const segmentStatementData = segmentData.statementSupports.find(
				segmentStatement => segmentStatement.idStatement === statement.idStatement,
			)

			const percentage = Math.round(segmentStatementData.expectedSupport * 100)
			const percentageDecimal = getDecimalPointNumberFromLeadingZeroNumber(
				segmentStatementData.expectedSupport,
			)
			const lowerBound = Math.round(
				segmentStatementData.expectedSupportErrorMargin.lowerBound * 100,
			)
			const upperBound = Math.round(
				segmentStatementData.expectedSupportErrorMargin.upperBound * 100,
			)
			const count = segmentStatementData.absoluteSupport

			const confidenceInterval =
				segmentStatementData.expectedSupport - segmentStatementData.confidenceInterval.lowerBound
			const confidence = Math.round(confidenceInterval * 100)
			const confidenceDecimal = getDecimalPointNumberFromLeadingZeroNumber(confidenceInterval)

			const elaborationCounters = segmentStatementData.elaborationCounters ?? {}

			const agreeCount = elaborationCounters.agreeCount
			const disagreeCount = elaborationCounters.disagreeCount
			const indifferentCount = elaborationCounters.indifferentCount

			idea[getSegmentTotalSupportDecimalPercentageKey(idSegment)] = Number(percentageDecimal)
			idea[getSegmentTotalSupportPercentageKey(idSegment)] = Number(percentage)
			idea[getSegmentTotalSupportCompletesKey(idSegment)] = Number(count)
			idea[getSegmentTotalSupportLowerBoundKey(idSegment)] = Number(lowerBound)
			idea[getSegmentTotalSupportUpperBoundKey(idSegment)] = Number(upperBound)
			idea[getSegmentConfidenceIntervalDecimalKey(idSegment)] = Number(confidenceDecimal)
			idea[getSegmentConfidenceIntervalKey(idSegment)] = Number(confidence)

			idea[getSegmentAgreeCountKey(idSegment)] = agreeCount
			idea[getSegmentDisagreeCountKey(idSegment)] = disagreeCount
			idea[getSegmentIndifferentCountKey(idSegment)] = indifferentCount
		})

		return idea
	})
}

/**
 * Derivators
 */
export const calculateLegendIdeas = (
	idStudy,
	reportType,
	studyObjectData,
	slideSettings,
	legendState,
	blobData,
) => {
	if (reportType !== REPORT_TYPES.OPEN_ANSWERS) {
		return []
	}

	if (studyObjectData === undefined) {
		return []
	}

	if (slideSettings === undefined) return []
	const { idStudyObject, idsSegments, themeViewSettings } = slideSettings

	const reportBlobType =
		themeViewSettings.isActive === true
			? REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT
			: REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT

	const dataArray = idsSegments.map(
		idSegment => blobData[getBlobDataKey(idStudy, idStudyObject, reportBlobType, idSegment)],
	)

	const totalSegmentData =
		blobData[getBlobDataKey(idStudy, idStudyObject, reportBlobType, tools.TOTAL_SEGMENT_UUID)]

	const currentSupportKey =
		themeViewSettings.isActive === true
			? SUPPORT_KEYS.THEME_SUPPORT
			: SUPPORT_KEYS.STATEMENT_SUPPORTS
	if (hasBlobData([...dataArray, totalSegmentData], currentSupportKey) === false) {
		return []
	}

	const calculateIdeasFunc =
		themeViewSettings.isActive === true ? calculateThemeIdeas : calculateStatementIdeas
	const mungeFunc = themeViewSettings.isActive === true ? mungeThemes : mungeIdeas

	const ideas = calculateIdeasFunc(
		idStudy,
		blobData,
		totalSegmentData,
		studyObjectData,
		slideSettings,
	)

	return mungeFunc(ideas, idsSegments, legendState)
}
