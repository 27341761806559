import { prepareQuery } from 'store/tools/graphql'
import { gql } from 'graphql-tag'

export const GET_LIBRARY_LIST = idAccount =>
	prepareQuery({
		variables: { idAccount },
		query: gql`
			query GET_LIBRARY_LIST($idAccount: UUID!) {
				libraries(idAccount: $idAccount) {
					publicLibraries {
						idLibraryListItem
						label
						note
					}
					privateLibraries {
						idLibraryListItem
						label
						note
					}
					internalLibraries {
						idLibraryListItem
						label
						note
					}
				}
			}
		`,
	})

export const GET_LIBRARY_DETAIL = () =>
	prepareQuery({
		options: { fetchPolicy: 'network-only' },
		query: gql`
			query GET_LIBRARY_DETAIL($idLibrary: UUID!, $idAccount: UUID!) {
				library(idLibrary: $idLibrary, idAccount: $idAccount) {
					idLibrary
					label
					flow
					segments
					idsMediaToCopy
				}
			}
		`,
	})
