import Apollo from 'apollo-boost'
import _ from 'lodash'
import { gql } from 'graphql-tag'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'

import config from 'config'

import { IGNORED_GRAPHQL_ERRORS } from 'constants/gql'
import { getToken, removeToken } from 'localStorage/token'
import { logError } from 'helpers/logError'
import { redirectToAuth } from 'helpers/auth/redirectToAuth'

export const mergeGql = (...args) => {
	return gql`
		${args.map(arg => arg.loc.source.body).join()}
	`
}

const AUTHORIZATION_TYPES = {
	USER_TOKEN: 'USER_TOKEN',
	PRESENTATION_TOKEN: 'PRESENTATION_TOKEN',
	NONE: 'NONE',
}

const SEGMENT_TYPENAME = 'Segment'
const SLIDE_TYPENAMES = ['OldSlideBase', 'OldSlide', 'SlideBase', 'Slide']
const PRESENTATION_TYPENAMES = ['Presentation', 'PresentationListItem']

const dataIdFromObject = object => {
	const { __typename } = object

	if (__typename === SEGMENT_TYPENAME) {
		return `${__typename}:${object.idStudy}_${object.idSegment}`
	}

	if (PRESENTATION_TYPENAMES.includes(__typename) === true) {
		return `${__typename}:${object.idPresentation}`
	}

	if (SLIDE_TYPENAMES.includes(__typename) === true) {
		return `${__typename}:${object.idSlide}`
	}

	return object[`id${__typename}`] !== undefined
		? `${__typename}:${object[`id${__typename}`]}`
		: defaultDataIdFromObject(object)
}

// GraphiQL https://us-central1-groupsolver-prod.cloudfunctions.net/graphql/graphiql
const createClient = (apiUrl, authorizationType) => {
	if (_.isNil(authorizationType) === true) {
		throw new Error('authorizationType must be defined')
	}

	const client = new Apollo({
		uri: apiUrl,
		request: operation => {
			if (authorizationType === AUTHORIZATION_TYPES.USER_TOKEN) {
				operation.setContext({
					headers: {
						gsAuth: getToken(),
					},
				})

				return
			}

			if (authorizationType === AUTHORIZATION_TYPES.PRESENTATION_TOKEN) {
				const searchParams = new URLSearchParams(window.location.search)
				const token = searchParams.get('t')

				const idPresentation = window.location.pathname.split('/')[2]

				operation.setContext({
					headers: {
						gspresentationauth: token,
						gsidpresentation: idPresentation,
					},
				})

				return
			}
		},
		onError: ({ graphQLErrors, networkError, operation }) => {
			const graphQLErrorMessage = _.get(graphQLErrors, '[0].message', null)

			if (IGNORED_GRAPHQL_ERRORS.includes(graphQLErrorMessage) === true) {
				return
			}

			// eslint-disable-next-line no-console
			console.error('GraphQL unexpected error:', graphQLErrors, networkError)

			const statusCode = _.get(networkError, 'statusCode', null)

			// ignore / don't log 401 and 410
			// 401 - wrong email/password
			// 410 - backend cannot find user by token
			const isAuthError = statusCode === 401 || statusCode === 410

			if (isAuthError === true && authorizationType === AUTHORIZATION_TYPES.USER_TOKEN) {
				removeToken()
				redirectToAuth()

				return
			}

			if (isAuthError === true && authorizationType === AUTHORIZATION_TYPES.PRESENTATION_TOKEN) {
				// do nothing, stay on page that displays "no access"
				return
			}

			// lor error that is not auth error OR
			// log auth error if we get it in client with different auth type
			const operationName = _.get(operation, 'operationName', 'unknown operation')
			const networkErrorMessage = _.get(networkError, 'message', '').trim()

			const isNetworkError =
				['Failed to fetch', 'Load failed'].includes(networkErrorMessage) === true
			const errorMessage = isNetworkError === true ? networkErrorMessage : operationName

			logError(new Error(errorMessage), { networkError, graphQLErrors, operation }, errorMessage)
		},
		cache: new InMemoryCache({
			dataIdFromObject,
		}),
	})

	return client
}

export const userClient = createClient(config.apiEndpointUser, AUTHORIZATION_TYPES.USER_TOKEN)
export const adminClient = createClient(config.apiEndpointAdmin, AUTHORIZATION_TYPES.USER_TOKEN)
export const authClient = createClient(config.apiEndpointAuth, AUTHORIZATION_TYPES.NONE)
export const publicPresentationClient = createClient(
	config.apiEndpointSharePresentation,
	AUTHORIZATION_TYPES.PRESENTATION_TOKEN,
)

export const prepareQuery = (query, fragments = []) => {
	let defaultQuery = {
		query: null,
		variables: null,
		options: {},
	}

	let gqlQuery = null
	if (query.kind !== undefined) {
		// raw gql-tag query
		gqlQuery = {
			...defaultQuery,
			query: query,
		}
	} else {
		// custom query object
		gqlQuery = { ...defaultQuery, ...query }
	}

	const request = Object.assign(
		{
			query: mergeGql(gqlQuery.query, ...fragments),
			variables: gqlQuery.variables,
		},
		gqlQuery.options,
	)

	return request
}

const isFunction = x => Boolean(x && x.call && x.apply)

export const prepareMutation = (mutation, fragments = []) => {
	let defaultMutation = {
		mutation: null,
		variables: null,
		prepareData: values => values,
		update: () => {},
		helpers: {},
		optimisticResponse: null,
	}

	let gqlMutation = null
	if (mutation.kind !== undefined) {
		// raw gql-tag query
		gqlMutation = {
			...defaultMutation,
			mutation: mutation,
		}
	} else {
		// custom query object
		gqlMutation = { ...defaultMutation, ...mutation }
	}

	if (isFunction(gqlMutation.prepareData) === false) {
		throw new Error('"prepareData" argument must be a function!')
	}

	const options = gqlMutation.options

	if (isFunction(gqlMutation.optimisticResponse) === true) {
		gqlMutation.helpers.optimisticResponse = gqlMutation.optimisticResponse
	}

	const request = Object.assign(
		{
			variables: gqlMutation.variables,
			mutation: mergeGql(gqlMutation.mutation, ...fragments),
			prepareData: gqlMutation.prepareData,
			update: gqlMutation.update,
			helpers: gqlMutation.helpers,
			optimisticResponse:
				isFunction(gqlMutation.optimisticResponse) === false
					? gqlMutation.optimisticResponse
					: null,
		},
		options,
	)

	return request
}

export const updateCache = mutation => {
	userClient.mutate(mutation)
}

export const readCache = (query, variables = {}) => {
	try {
		return userClient.cache.readQuery({ query, variables })
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error('graphql.js: cannot read from cache', e)
		return null
	}
}
