import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import { Field } from 'formik'
import { Query } from 'react-apollo'

import { STUDY_DETAIL } from 'gql/queries/study'

import FormattedInput from 'components/_formik/_complex/FormattedInput'
import Label from 'components/_formik/_base/Label'
import Toggle from 'components/_formik/_base/Toggle'

import classes from './QuotaDefinition.module.scss'

const QuotaDefinition = ({ disabled, idStudy, fieldNamePrefix, quota }) => {
	const intl = useIntl()
	return (
		<Query {...STUDY_DETAIL(idStudy, 'cache-only')}>
			{({ data, error, loading }) => {
				if (error !== undefined || loading === true) return null

				const respondentsRequested = data.study.respondents.requested

				const normalizeInput = input => {
					const numberInput = Number(input)

					if (quota.isPercentage === true) {
						return numberInput > 100 ? 100 : Math.round(numberInput)
					} else if (numberInput > respondentsRequested) {
						return Number(respondentsRequested)
					}

					return numberInput
				}

				return (
					<div className={classes['quota-definition']}>
						<div>
							<Label label={intl.formatMessage({ id: 'quota.limit_value' })} />
							<Field
								component={FormattedInput}
								disabled={disabled}
								format={value => value}
								name={`${fieldNamePrefix}.number`}
								normalize={normalizeInput}
								placeholder={intl.formatMessage({ id: 'number_placeholder' })}
								type="number"
								validate={{ required: true, min: 1 }}
							/>
						</div>
						<div>
							<Label label={intl.formatMessage({ id: 'quota.units' })} />
							<Field
								component={Toggle}
								disabled={disabled}
								labelFalse={intl.formatMessage({ id: 'completes' }, { count: 0 })}
								labelTrue={intl.formatMessage({ id: 'percent' })}
								name={`${fieldNamePrefix}.isPercentage`}
								toggleStyle="study-design__condition"
							/>
						</div>
					</div>
				)
			}}
		</Query>
	)
}

QuotaDefinition.propTypes = {
	disabled: PropTypes.bool.isRequired,
	fieldNamePrefix: PropTypes.string.isRequired,
	idStudy: PropTypes.string.isRequired,
	quota: PropTypes.object.isRequired,
}

export default QuotaDefinition
