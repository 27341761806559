import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import isOptionsFilterValid from 'routes/_study/StudyDesign/_store/helpers/flowValidation/validateModule/_isOptionsFilterValid'
import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

import Input from 'components/_formik/_base/Input'
import Label from 'components/_formik/_base/Label'

const OptionsFilter = props => {
	const intl = useIntl()

	if (props.isUserInternalEmployee === false) {
		return null
	}

	const fieldName =
		props.moduleType === VISUAL_FLOW_MODULE_TYPES.A_CHOICE ? 'filter.variable' : 'filter'

	return (
		<div>
			<Label
				label={intl.formatMessage({
					id: 'filter_options_by',
				})}
			/>
			<Field
				component={Input}
				disabled={props.disabled}
				name={fieldName}
				placeholder={intl.formatMessage({
					id: 'filter_options_by',
				})}
				type="text"
			/>
			{isOptionsFilterValid({ definition: props.values, type: props.moduleType }) === false && (
				<span className="title-error">
					{intl.formatMessage({
						id: 'options.filter.invalid',
					})}
				</span>
			)}
		</div>
	)
}

OptionsFilter.propTypes = {
	disabled: PropTypes.bool.isRequired,
	isUserInternalEmployee: PropTypes.bool.isRequired,
	moduleType: PropTypes.string.isRequired,
	values: PropTypes.object.isRequired,
}

export default OptionsFilter
