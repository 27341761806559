import _ from 'lodash'
import { compare } from 'natural-orderby'

import { filterData, sortData } from 'helpers/sortAndFilter'
import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { getDecimalPointNumberFromLeadingZeroNumber } from 'helpers/reportBuilder/getDecimalPointNumberFromLeadingZeroNumber'
import { getHasThemesData } from 'helpers/reportBuilder/getHasThemesData'
import { getIsCroppedStatement } from 'helpers/reportBuilder/getIsCroppedStatement'
import { getStatementThemeName } from 'helpers/statementThemes'
import { getThemeParams } from 'helpers/reportBuilder/getLetterAndThemeParams'
import { hasBlobData } from 'helpers/reportBuilder/hasBlobData'
import { createLines } from 'helpers/reportBuilder/chart/createLines'

import { tools } from 'constants/tools'
import { REPORT_TYPES, REPORT_BLOB_TYPES, SUPPORT_KEYS } from 'constants/reports'

/**
 * HELPERS
 */
const toPercent = number => _.round(_.round(number, 2) * 100, 2)

const calculateIdeaData = (idea, chosenSegmentData) => {
	const supportData = chosenSegmentData.statementSupports.find(
		statement => statement.idStatement === idea.idStatement,
	)

	return {
		...idea,
		support: {
			expectedSupport: supportData.expectedSupport,
			supportStrength: supportData.supportStrength,
			totalSeen: supportData.totalSeen,
		},
		support_total: {
			expectedSupport: idea.expectedSupport,
			supportStrength: idea.supportStrength,
			totalSeen: idea.totalSeen,
		},
	}
}

const getLines = answer => {
	const lengthRangeTo = 31
	const lengthLimit = 51

	const ideaLines = createLines(answer, lengthRangeTo, lengthLimit)

	return ideaLines.join('\n')
}

const calculateThemeData = (theme, totalSegmentThemesData, chosenSegmentThemeData, excludeList) => {
	const totalSegmentThemeData = totalSegmentThemesData.themeSupport[theme.idStatementTheme]
	const themeData = chosenSegmentThemeData.themeSupport[theme.idStatementTheme]

	return {
		absoluteSupport: toPercent(themeData.absoluteSupport),
		answersCount: theme.statements.length,
		decimalSupportPercent: getDecimalPointNumberFromLeadingZeroNumber(
			themeData.supportStrength.lowerBound,
		),
		expectedSupport: toPercent(themeData.expectedSupport),
		isExcluded: excludeList.includes(theme.idStatementTheme),
		fill: theme.color,
		idStatement: theme.idStatementTheme,
		letter: totalSegmentThemeData.letter,
		name: theme.name,
		answer: theme.name,
		relativeSupport: null,
		supportCompletes: null,
		supportPercent: toPercent(themeData.supportStrength.lowerBound),
		totalExpectedSupport: toPercent(totalSegmentThemeData.expectedSupport),
		totalSupportCompletes: null,
		totalSupportPercent: toPercent(totalSegmentThemeData.supportStrength.lowerBound),
	}
}

const roundIdea = idea => ({
	...idea,
	relativeSupport: _.round(idea.relativeSupport, 2),
	support_total: {
		...idea.support_total,
		expectedSupport: toPercent(_.get(idea, 'support_total.expectedSupport', 0)),
		supportStrength: {
			...idea.support_total.supportStrength,
			lowerBound: toPercent(_.get(idea, 'support_total.supportStrength.lowerBound', 0)),
		},
	},
	support: {
		...idea.support,
		decimalSupportPercent: getDecimalPointNumberFromLeadingZeroNumber(
			_.get(idea, 'support.supportStrength.lowerBound', 0),
		),
		expectedSupport: toPercent(_.get(idea, 'support.expectedSupport', 0)),
		supportStrength: {
			...idea.support.supportStrength,
			lowerBound: toPercent(_.get(idea, 'support.supportStrength.lowerBound', 0)),
		},
	},
})

const sortIdeaByTotal = (a, b) => {
	const res =
		a.support_total.supportStrength.lowerBound - b.support_total.supportStrength.lowerBound

	if (res < 0) return 1
	if (res > 0) return -1

	return compare(a.label, b.label)
}

const formatIdea = (idea, allIdeas, settings, statementThemes, excludeList) => {
	const statementThemeParams = getThemeParams(
		idea,
		idea,
		statementThemes,
		allIdeas,
		settings.themeViewSettings.isActive === false ? settings.useThemes : false,
	)

	const themeName = getStatementThemeName(statementThemeParams, true)

	return {
		absoluteSupport: idea.absoluteSupport,
		decimalSupportPercent: idea.support.decimalSupportPercent,
		expectedSupport: idea.support.expectedSupport,
		fill: statementThemeParams.color,
		idStatement: idea.idStatement,
		letter: idea.letter,
		isExcluded: excludeList.includes(idea.idStatement),
		answer: getLines(idea.label),
		name: idea.label,
		themeName,
		relativeSupport: idea.relativeSupport,
		supportCompletes: idea.support.totalSeen,
		supportPercent: idea.support.supportStrength.lowerBound,
		totalExpectedSupport: idea.support_total.expectedSupport,
		totalSupportCompletes: idea.support_total.totalSeen,
		totalSupportPercent: idea.support_total.supportStrength.lowerBound,
	}
}

/**
 * SELECTORS & CALCULATORS
 */
export const calculateDataset = (idStudy, calculatedSlide, blobData, studyObjectData) => {
	const idStudyObject = _.get(calculatedSlide, 'settings.idStudyObject', null)
	const idsSegments = _.get(calculatedSlide, 'settings.idsSegments', [])

	if (studyObjectData === undefined) {
		return []
	}

	const totalSegmentData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT,
				tools.TOTAL_SEGMENT_UUID,
			)
		]
	const chosenSegmentData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT,
				idsSegments[0],
			)
		]

	const totalSegmentThemesData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	const chosenSegmentThemesData =
		blobData[
			getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT, idsSegments[0])
		]

	if (
		calculatedSlide.slideType !== REPORT_TYPES.IDEACLOUD ||
		hasBlobData([totalSegmentData, chosenSegmentData], SUPPORT_KEYS.STATEMENT_SUPPORTS) === false ||
		hasBlobData([totalSegmentThemesData, chosenSegmentThemesData], SUPPORT_KEYS.THEME_SUPPORT) ===
			false
	) {
		return { statementSupports: [], allIdeasLength: 0 }
	}

	const {
		settings,
		legendState: { sorted, filtered },
	} = calculatedSlide

	const { statementThemes } = studyObjectData[calculatedSlide.settings.idStudyObject]
	const { themeViewSettings } = settings

	if (themeViewSettings.isActive === true) {
		const isThemesSupportDefined = getHasThemesData(statementThemes, totalSegmentThemesData)

		if (isThemesSupportDefined === false) {
			return { statementSupports: [], allIdeasLength: 0 }
		}

		const themesData = statementThemes.map(theme =>
			calculateThemeData(
				theme,
				totalSegmentThemesData,
				chosenSegmentThemesData,
				themeViewSettings.excludedThemes,
			),
		)

		return {
			statementSupports: sortData(filterData(themesData, filtered), sorted),
			allIdeasLength: themesData.length,
		}
	}

	const formattedStatements = totalSegmentData.statementSupports
		.map(idea => calculateIdeaData(idea, chosenSegmentData))
		.map(roundIdea)
		.slice()
		.sort(sortIdeaByTotal)
		.map((idea, _i, allIdeas) =>
			formatIdea(
				idea,
				allIdeas,
				calculatedSlide.settings,
				statementThemes,
				settings.hiddenStatements,
			),
		)

	const sortedAndFilteredStatements = sortData(filterData(formattedStatements, filtered), sorted)

	return {
		statementSupports: sortedAndFilteredStatements,
		allIdeasLength: formattedStatements.length,
	}
}

export const calculateLegendStatements = (calculatedSlide, dataset) => {
	const { hiddenStatements, themeViewSettings } = calculatedSlide.settings

	const excludeList =
		themeViewSettings.isActive === true ? themeViewSettings.excludedThemes : hiddenStatements

	const filteredStatements = dataset.statementSupports.filter(
		statement =>
			excludeList.includes(statement.idStatement ?? statement.idStatementTheme) === false &&
			getIsCroppedStatement(calculatedSlide, statement) === false,
	)

	if (themeViewSettings.isActive === true) {
		return filteredStatements.map(statement => ({
			...statement,
			toExport: () => [
				statement.letter,
				statement.name,
				statement.answersCount,
				statement.decimalSupportPercent,
			],
		}))
	}

	return filteredStatements.map(statement => {
		return {
			...statement,
			toExport: () => [
				statement.letter,
				statement.name,
				statement.decimalSupportPercent,
				statement.supportCompletes,
				statement.themeName,
			],
		}
	})
}
