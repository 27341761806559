import colors from 'styles/themes/color.scss'

export const COLORS = {
	...colors,
	BBB: '#bbb', // BBB color will eventually get replaced as it is web unsafe color
	MAGENTA_BRIGHT: '#d0225d',
	MEDIUM_DARK_GREY: '#444',
	REPORT_BUILDER_NO_THEME: '#a9a9a9',
	GS_LOGO_TEXT: '#26335c',
}

export const PALETTE_COLORS = [
	{
		colorName: 'black',
		colorHex: COLORS.GREY_10,
	},
	{
		colorName: 'white',
		colorHex: COLORS.WHITE,
	},
	{
		colorName: 'blue',
		colorHex: COLORS.PRIMARY_45,
	},
	{
		colorName: 'green',
		colorHex: COLORS.GREEN_30,
	},
	{
		colorName: 'red',
		colorHex: COLORS.RED_40,
	},
]

export const ANSWER_MANAGER_COLORS = [
	{
		colorName: 'azure_blue',
		colorHex: '#2196F3',
	},
	{
		colorName: 'red',
		colorHex: '#F44336',
	},
	{
		colorName: 'forest_green',
		colorHex: '#009688',
	},
	{
		colorName: 'sky_blue',
		colorHex: '#03A9F4',
	},
	{
		colorName: 'yellow',
		colorHex: '#FFC107',
	},
	{
		colorName: 'magenta',
		colorHex: '#E91E63',
	},
	{
		colorName: 'cyan',
		colorHex: '#00BCD4',
	},
	{
		colorName: 'orange',
		colorHex: '#FF5722',
	},
	{
		colorName: 'green',
		colorHex: '#4CAF50',
	},
	{
		colorName: 'eggplant',
		colorHex: '#9C27B0',
	},
	{
		colorName: 'yellow_orange',
		colorHex: '#FF9800',
	},
	{
		colorName: 'lime',
		colorHex: '#8BC34A',
	},
	{
		colorName: 'violet',
		colorHex: '#814BE1',
	},
	{
		colorName: 'royal_blue',
		colorHex: '#475AC4',
	},
]
