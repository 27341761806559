import { prepareMutation } from 'store/tools/graphql'
import { gql } from 'graphql-tag'

export const ADMIN_ADD_ACCOUNT_MEDIA_OBJECT = variables =>
	prepareMutation({
		variables,
		mutation: gql`
			mutation ADMIN_ADD_ACCOUNT_MEDIA_OBJECT(
				$idAccount: UUID!
				$addMediaObjectInput: AddMediaObjectInput!
			) {
				account {
					addAccountMediaObject(idAccount: $idAccount, addMediaObjectInput: $addMediaObjectInput) {
						idMediaObject
						url
						filename
						type
						errors
						mediaUploadUrl
						isUploadFinished
						isPostProcessingFinished
					}
				}
			}
		`,
	})

export const ADMIN_ADD_USER_MEDIA_OBJECT = variables =>
	prepareMutation({
		variables,
		mutation: gql`
			mutation ADMIN_ADD_USER_MEDIA_OBJECT(
				$idUser: UUID!
				$addMediaObjectInput: AddMediaObjectInput!
			) {
				user {
					addUserMediaObject(idUser: $idUser, addMediaObjectInput: $addMediaObjectInput) {
						idMediaObject
						url
						filename
						type
						errors
						mediaUploadUrl
						isUploadFinished
						isPostProcessingFinished
					}
				}
			}
		`,
	})
