import { OBJECT_TYPES } from '../OBJECT_TYPES'

const isDatasetType = module =>
	module.type === OBJECT_TYPES.ALLOCATION ||
	module.type === OBJECT_TYPES.CHOICE ||
	module.type === OBJECT_TYPES.FREE_TEXT ||
	module.type === OBJECT_TYPES.HEATMAP ||
	module.type === OBJECT_TYPES.OEQ ||
	module.type === OBJECT_TYPES.RANKING

export const validate = (module, flow, config = null) => {
	let numOfModules = flow.length
	let numOfDatasets = 0
	let numOfOEQs = 0

	flow.forEach(flowModule => {
		if (isDatasetType(flowModule) === true && flowModule.order.includes('matrix') === false) {
			numOfDatasets++
		}

		if (flowModule.type === OBJECT_TYPES.OEQ) {
			numOfOEQs++
		}
	})

	const validationErrors = []

	// comment this out for now
	// if (numOfDatasets > config.MAX_DATASETS) {
	// 	validationErrors.push(`FLOW_LIMIT: max number of datasets (${config.MAX_DATASETS}) breached`)
	// }
	// if (numOfOEQs > config.MAX_OEQ) {
	// 	validationErrors.push(
	// 		`FLOW_LIMIT: max number of open ended questions (${config.MAX_OEQ}) breached`
	// 	)
	// }

	if (validationErrors.length > 0) {
		return {
			infos: null,
			warnings: null,
			errors: validationErrors,
		}
	} else {
		return null
	}
}

export default validate
