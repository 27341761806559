import { gql } from 'graphql-tag'

export const statement = gql`
	fragment statement on Statement {
		idStatement
		label
		autoTranslatedLabel
		isInvalid
		elaborationCounters {
			agreeCount
			disagreeCount
			indifferentCount
		}
		totalSegmentSupport
	}
`

export const statementGroup = gql`
	fragment statementGroup on StatementGroup {
		idStatementGroup
		label
		autoTranslatedLabel
		idMainStatement
		groupedStatements {
			idStatement
			label
			autoTranslatedLabel
			isInvalid
		}
		elaborationCounters {
			agreeCount
			disagreeCount
			indifferentCount
		}
		totalSegmentSupport
	}
`

export const statementTheme = gql`
	fragment statementTheme on StatementTheme {
		idStatementTheme
		statements
		name
		originalLanguageThemeName
		color
		meanSimilarity
		similarityStandardDeviation
		totalSegmentSupport
	}
`
