import { prepareMutation } from 'store/tools/graphql'
import { gql } from 'graphql-tag'

import { logError } from 'helpers/logError'
import { getUploadedFileKey } from 'helpers/gql/getUploadedFileKey'

import { STUDY_NOTE_TYPES } from 'constants/studyNotes'
import { STUDY_ACTIONS } from 'constants/study'

import { GET_LIBRARY_LIST } from 'gql/queries/library'
import {
	STUDY_STATUS,
	GET_DATA_IMPORT,
	STUDY_NOTES,
	STUDY_DETAIL,
	GET_SEGMENTS,
	GET_IMPORTED_MODULES,
	GET_STUDY_QUANT_QUAL_SLIDES,
} from 'gql/queries/study'
import { USER_PAGE } from 'gql/queries/user'
import {
	dataImport,
	flow,
	note,
	quantQualSlide,
	quotaStatistics,
	respondents,
} from 'gql/fragments/study'
import { mediaObject } from 'gql/fragments/mediaObject'
import { segment } from 'gql/fragments/segments'

export const STATE_TRANSITION = (idStudy, action, onCompleted, onError) => {
	return prepareMutation(
		{
			variables: {
				idStudy,
				studyStateTransitionInput: {
					action,
				},
			},
			mutation: gql`
				mutation STATE_TRANSITION(
					$idStudy: UUID!
					$studyStateTransitionInput: StudyStateTransitionInput!
				) {
					study {
						stateTransition(
							idStudy: $idStudy
							studyStateTransitionInput: $studyStateTransitionInput
						) {
							isLibrary
							isPostAnalytics
							idAccount
							idStudy
							...flow
							...respondents
							label
							state
							respondentSources {
								idRespondentSource
							}
							slides {
								idStudy
								idSlide
								settings
								order
								slideType
							}
							oldSlides {
								idStudy
								idSlide
								label
								order
								slideType
							}
						}
					}
				}
			`,
			options: { onCompleted, onError },
			update: (cache, { data }) => {
				if (action === STUDY_ACTIONS.clientLaunch.action) {
					const { idAccount } = data.study.stateTransition
					const { accounts, user } = cache.readQuery({ ...USER_PAGE() })

					cache.writeQuery({
						...USER_PAGE(),
						data: {
							accounts: accounts.map(account => {
								if (account.idAccount !== idAccount) {
									return account
								}

								return {
									...account,
									license: {
										...account.license,
										launchedStudies: account.license.launchedStudies + 1,
									},
								}
							}),
							user,
						},
					})

					const { study } = cache.readQuery({ ...STUDY_DETAIL(idStudy) })
					cache.writeQuery({
						...STUDY_DETAIL(idStudy),
						data: {
							study: {
								...study,
								respondentSources: study.respondentSources.filter(oldRespondentSource =>
									data.study.stateTransition.respondentSources.find(
										({ idRespondentSource }) =>
											idRespondentSource === oldRespondentSource.idRespondentSource,
									),
								),
							},
						},
					})
				}
			},
		},
		[flow, respondents],
	)
}

export const CREATE_DATA_DOWNLOAD = idStudy => {
	return prepareMutation({
		mutation: gql`
			mutation CREATE_DATA_DOWNLOAD(
				$idStudy: UUID!
				$studyDataDownloadInput: StudyDataDownloadInput
			) {
				study {
					createDataDownload(idStudy: $idStudy, studyDataDownloadInput: $studyDataDownloadInput) {
						idDownload
						label
						status
						startedTime
						requestedTime
						completedTime
						platformFile {
							idPlatformFile
							url
						}
						requests {
							title
							type
							filters {
								idQuestions
							}
							options {
								key
								value
							}
							format
						}
					}
				}
			}
		`,
		update: (cache, { data }) => {
			const { study } = cache.readQuery({ ...STUDY_STATUS(idStudy) })
			const createDataDownload = data.study.createDataDownload

			// download was added by STUDY_STATUS live query
			if (
				study.dataDownload.downloads.some(
					download => download.idDownload === createDataDownload.idDownload,
				) === true
			) {
				return
			}

			cache.writeQuery({
				...STUDY_STATUS(idStudy),
				data: {
					study: {
						...study,
						idStudy,
						dataDownload: {
							downloads: [createDataDownload].concat(study.dataDownload.downloads),
							__typename: study.dataDownload.__typename,
						},
					},
				},
			})
		},
	})
}

export const CANCEL_DATA_DOWNLOAD = (idStudy, studyCancelDataDownloadInput, onCompleted) =>
	prepareMutation({
		variables: { idStudy, studyCancelDataDownloadInput },
		options: { onCompleted },
		mutation: gql`
			mutation CANCEL_DATA_DOWNLOAD(
				$idStudy: UUID!
				$studyCancelDataDownloadInput: StudyCancelDataDownloadInput!
			) {
				study {
					cancelDataDownload(
						idStudy: $idStudy
						studyCancelDataDownloadInput: $studyCancelDataDownloadInput
					) {
						idDownload
						status
					}
				}
			}
		`,
	})

export const DELETE_DATA_DOWNLOAD = (idStudy, studyDeleteDataDownloadInput, onCompleted) =>
	prepareMutation({
		variables: { idStudy, studyDeleteDataDownloadInput },
		options: { onCompleted },
		mutation: gql`
			mutation DELETE_DATA_DOWNLOAD(
				$idStudy: UUID!
				$studyDeleteDataDownloadInput: StudyDeleteDataDownloadInput!
			) {
				study {
					deleteDataDownload(
						idStudy: $idStudy
						studyDeleteDataDownloadInput: $studyDeleteDataDownloadInput
					) {
						idDownload
					}
				}
			}
		`,
		update: cache => {
			const { study } = cache.readQuery({ ...STUDY_STATUS(idStudy) })
			cache.writeQuery({
				...STUDY_STATUS(idStudy),
				data: {
					study: {
						...study,
						idStudy,
						dataDownload: {
							downloads: study.dataDownload.downloads.filter(
								download =>
									studyDeleteDataDownloadInput.idDownloads.includes(download.idDownload) === false,
							),
							__typename: study.dataDownload.__typename,
						},
					},
				},
			})
		},
	})

export const CREATE_DATA_IMPORT = (idStudy, onError) =>
	prepareMutation(
		{
			options: { onError },
			mutation: gql`
				mutation CREATE_DATA_IMPORT(
					$idStudy: UUID!
					$createDataImportInput: CreateDataImportInput!
				) {
					study {
						createDataImport(idStudy: $idStudy, createDataImportInput: $createDataImportInput) {
							...dataImport
						}
					}
				}
			`,
			update: (cache, { data }) => {
				const { isCommunity } = data.study.createDataImport
				const uploadedFileKey = getUploadedFileKey(isCommunity)

				const { study } = cache.readQuery({ ...GET_DATA_IMPORT(idStudy, isCommunity) })

				cache.writeQuery({
					...GET_DATA_IMPORT(idStudy, isCommunity),
					data: {
						study: {
							...study,
							[uploadedFileKey]: data.study.createDataImport,
						},
					},
				})
			},
		},
		[dataImport],
	)

export const DATA_IMPORT_SET_RULES = (idStudy, onCompleted) =>
	prepareMutation(
		{
			options: { onCompleted, refetchQueries: [GET_IMPORTED_MODULES(idStudy)] },
			mutation: gql`
				mutation DATA_IMPORT_SET_RULES(
					$idStudy: UUID!
					$idDataImport: UUID!
					$setDataImportRulesInput: SetDataImportRulesInput!
				) {
					study {
						setDataImportRules(
							idStudy: $idStudy
							idDataImport: $idDataImport
							setDataImportRulesInput: $setDataImportRulesInput
						) {
							...dataImport
						}
					}
				}
			`,
			update: (cache, { data }) => {
				const { idDataImport, rules } = data.study.setDataImportRules

				const { study } = cache.readQuery({ ...STUDY_DETAIL(idStudy) })

				let key = null

				if (study.dataImport?.idDataImport === idDataImport) {
					key = 'dataImport'
				}

				if (study.community?.idDataImport === idDataImport) {
					key = 'community'
				}

				if (key === null) {
					// NOTE: Should never happen, but just in case...
					throw new Error(
						`idDataImport "${idDataImport}" does not match either the community or dataImport`,
					)
				}

				cache.writeQuery({
					...STUDY_DETAIL(idStudy),
					data: {
						study: {
							...study,
							[key]: {
								...study[key],
								rules,
							},
						},
					},
				})
			},
		},
		[dataImport],
	)

export const DELETE_DATA_IMPORT = (idStudy, idDataImport, onCompleted) =>
	prepareMutation({
		variables: { idStudy, idDataImport },
		options: { onCompleted, refetchQueries: [GET_SEGMENTS(idStudy)] },
		mutation: gql`
			mutation DELETE_DATA_IMPORT($idStudy: UUID!, $idDataImport: UUID!) {
				study {
					deleteDataImport(idStudy: $idStudy, idDataImport: $idDataImport) {
						idDataImport
						isCommunity
					}
				}
			}
		`,
		update: (cache, { data }) => {
			const { study } = cache.readQuery({ ...STUDY_DETAIL(idStudy) })

			const { isCommunity } = data.study.deleteDataImport
			const uploadedFileKey = getUploadedFileKey(isCommunity)

			cache.writeQuery({
				...STUDY_DETAIL(idStudy),
				data: {
					study: {
						...study,
						[uploadedFileKey]: null,
						importedModules: study.importedModules.filter(
							importedModule => importedModule.isCommunity !== isCommunity,
						),
					},
				},
			})
		},
	})

export const DATA_IMPORT_UPDATE_ROWS = () =>
	prepareMutation({
		mutation: gql`
			mutation DATA_IMPORT_UPDATE_ROWS(
				$idStudy: UUID!
				$idDataImport: UUID!
				$addDataImportRowsInput: AddDataImportRowsInput!
			) {
				study {
					addDataImportRows(
						idStudy: $idStudy
						idDataImport: $idDataImport
						addDataImportRowsInput: $addDataImportRowsInput
					) {
						idDataImport
						isComplete
					}
				}
			}
		`,
	})

const updateFlowRefetchFields = `idStudy
isLibrary
isPostAnalytics
state
label
...flow
...respondents
mediaFiles {
	...mediaObject
}
segments {
	...segment
}`

const updateFlowFragments = [flow, respondents, mediaObject, segment]

export const VISUAL_FLOW_UPDATE = (idStudy, studyVisualFlowInput) =>
	prepareMutation(
		{
			variables: { idStudy, studyVisualFlowInput },
			mutation: gql`
				mutation VISUAL_FLOW_UPDATE($idStudy: UUID!, $studyVisualFlowInput: StudyVisualFlowInput!) {
					study {
						updateVisualFlow(idStudy: $idStudy, studyVisualFlowInput: $studyVisualFlowInput) {
							${updateFlowRefetchFields}
						}
					}
				}
			`,
		},
		updateFlowFragments,
	)

export const LIVE_VISUAL_FLOW_UPDATE = (idStudy, studyVisualFlowInput) =>
	prepareMutation(
		{
			variables: { idStudy, studyVisualFlowInput },
			mutation: gql`
				mutation LIVE_VISUAL_FLOW_UPDATE($idStudy: UUID!, $studyVisualFlowInput: StudyVisualFlowInput!) {
					study {
						updateLiveFlow(idStudy: $idStudy, studyVisualFlowInput: $studyVisualFlowInput) {
							${updateFlowRefetchFields}
							quotaStatistics {
								...quotaStatistics
							}
						}
					}
				}
			`,
		},
		[...updateFlowFragments, quotaStatistics],
	)

export const ADD_STUDY_MEDIA_OBJECT = variables =>
	prepareMutation({
		variables,
		mutation: gql`
			mutation ADD_STUDY_MEDIA_OBJECT($idStudy: UUID!, $addMediaObjectInput: AddMediaObjectInput!) {
				study {
					addStudyMediaObject(idStudy: $idStudy, addMediaObjectInput: $addMediaObjectInput) {
						idMediaObject
						url
						filename
						type
						errors
						mediaUploadUrl
						isUploadFinished
						isPostProcessingFinished
					}
				}
			}
		`,
		update: (cache, { data }) => {
			const { idStudy } = variables
			const { study } = cache.readQuery({ ...STUDY_DETAIL(idStudy) })
			const newMediaObject = data.study.addStudyMediaObject
			cache.writeQuery({
				...STUDY_DETAIL(idStudy),
				data: {
					study: {
						...study,
						mediaFiles: [...study.mediaFiles, newMediaObject],
					},
				},
			})
		},
	})

export const DELETE_MEDIA_OBJECTS = variables =>
	prepareMutation({
		variables,
		mutation: gql`
			mutation DELETE_MEDIA_OBJECTS($idStudy: UUID!, $idsMediaObjects: [UUID!]!) {
				study {
					deleteMediaObjects(idStudy: $idStudy, idsMediaObjects: $idsMediaObjects)
				}
			}
		`,
		update: (cache, { data }) => {
			const { idStudy } = variables
			const { study } = cache.readQuery({ ...STUDY_DETAIL(idStudy) })
			const objectToDelete = data.study.deleteMediaObjects[0]
			cache.writeQuery({
				...STUDY_DETAIL(idStudy),
				data: {
					study: {
						...study,
						mediaFiles: study.mediaFiles.filter(object => object.idMediaObject !== objectToDelete),
					},
				},
			})
		},
	})

export const UPDATE_STUDY_SETTINGS = idAccount =>
	prepareMutation(
		{
			mutation: gql`
				mutation UPDATE_STUDY_SETTINGS($idStudy: UUID!, $studySettingsInput: StudySettingsInput!) {
					study {
						updateSettings(idStudy: $idStudy, studySettingsInput: $studySettingsInput) {
							idStudy
							isLibrary
							isPostAnalytics
							label
							note
							...respondents
						}
					}
				}
			`,
			update: (cache, { data: { study } }) => {
				if (study.updateSettings.isLibrary === true) {
					const data = { libraries: [] }

					try {
						data.libraries = cache.readQuery({
							query: GET_LIBRARY_LIST(idAccount).query,
							variables: { idAccount },
						}).libraries
					} catch (error) {
						// cache.readQuery throws error if there is no result
						// we don't have libraries in cache if we did not go throught /libraries nor /study-design
						// therefore, we don't update anything
						return
					}

					cache.writeQuery({
						query: GET_LIBRARY_LIST(idAccount).query,
						variables: { idAccount },
						data: {
							libraries: {
								...data.libraries,
								privateLibraries: [
									...data.libraries.privateLibraries.filter(
										cachedLibrary =>
											cachedLibrary.idLibraryListItem !== study.updateSettings.idStudy,
									),
									{
										...data.libraries.privateLibraries.find(
											cachedLibrary =>
												cachedLibrary.idLibraryListItem === study.updateSettings.idStudy,
										),
										label: study.updateSettings.label,
										note: study.updateSettings.note,
									},
								],
							},
						},
					})
				}
			},
		},
		[respondents],
	)

export const UPDATE_STUDY_TAGS = () =>
	prepareMutation({
		mutation: gql`
			mutation UPDATE_STUDY_TAGS($idStudy: UUID!, $studyTagsInput: StudyTagsInput!) {
				study {
					updateTags(idStudy: $idStudy, studyTagsInput: $studyTagsInput) {
						idStudy
						tags
					}
				}
			}
		`,
	})

export const RESPONDENTS_SET_IS_EXCLUDED = (idStudy, idsRespondents, isExcluded) =>
	prepareMutation({
		variables: {
			idStudy,
			setRespondentsIsExcludedInput: {
				idsRespondents,
				isExcluded,
			},
		},
		mutation: gql`
			mutation RESPONDENTS_SET_IS_EXCLUDED(
				$idStudy: UUID!
				$setRespondentsIsExcludedInput: SetRespondentsIsExcludedInput!
			) {
				study {
					setRespondentsIsExcluded(
						idStudy: $idStudy
						setRespondentsIsExcludedInput: $setRespondentsIsExcludedInput
					) {
						idRespondent
						isExcluded
					}
				}
			}
		`,
	})

export const UPDATE_QUOTA = () =>
	prepareMutation(
		{
			mutation: gql`
				mutation UPDATE_QUOTA($idStudy: UUID!, $updateQuotaInput: QuotaInput!) {
					study {
						updateQuota(idStudy: $idStudy, updateQuotaInput: $updateQuotaInput) {
							...quotaStatistics
						}
					}
				}
			`,
		},
		[quotaStatistics],
	)

export const MARK_STUDY_TO_DELETE = () =>
	prepareMutation({
		mutation: gql`
			mutation MARK_STUDY_TO_DELETE($idStudy: UUID!, $userPassword: String!) {
				study {
					markStudyToDelete(idStudy: $idStudy, userPassword: $userPassword) {
						idStudy
						deleteOn
					}
				}
			}
		`,
	})

export const CANCEL_STUDY_DELETE = idStudy =>
	prepareMutation({
		variables: {
			idStudy,
		},
		mutation: gql`
			mutation CANCEL_STUDY_DELETE($idStudy: UUID!) {
				study {
					cancelStudyDelete(idStudy: $idStudy) {
						idStudy
						deleteOn
					}
				}
			}
		`,
	})

export const CREATE_STUDY_NOTE = (idStudy, onCompleted, onError) =>
	prepareMutation(
		{
			mutation: gql`
				mutation CREATE_STUDY_NOTE($idStudy: UUID!, $createNoteInput: CreateNoteInput!) {
					study {
						createStudyNote(idStudy: $idStudy, createNoteInput: $createNoteInput) {
							...note
						}
					}
				}
			`,
			update: (cache, { data }) => {
				const { study } = cache.readQuery({ ...STUDY_NOTES(idStudy) })
				const createStudyNote = data.study.createStudyNote
				cache.writeQuery({
					...STUDY_NOTES(idStudy),
					data: {
						study: {
							...study,
							idStudy,
							notes: [createStudyNote].concat(study.notes),
						},
					},
				})
			},
			options: { onCompleted, onError },
		},
		[note],
	)

export const DELETE_STUDY_NOTE = (comment, onError) => {
	const { idStudy, idNote } = comment
	return prepareMutation(
		{
			mutation: gql`
				mutation DELETE_STUDY_NOTE($idStudy: UUID!, $idNote: UUID!) {
					study {
						deleteStudyNote(idStudy: $idStudy, idNote: $idNote) {
							...note
						}
					}
				}
			`,
			variables: { idStudy, idNote },
			optimisticResponse: {
				__typename: 'Mutation',
				study: {
					__typename: 'StudyMutation',
					deleteStudyNote: {
						__typename: 'StudyNote',
						idNote,
						idStudy,
						idItem: comment.idItem,
						idParentNote: comment.idParentNote,
						type: STUDY_NOTE_TYPES.VISUAL_FLOW,
						text: 'backend_translate.note_deleted',
						task: {
							__typename: 'Task',
							isTask: false,
							isDone: false,
						},
						meta: {
							__typename: 'Meta',
							created: comment.meta.created,
							updated: comment.meta.updated,
						},
						userInfo: {
							__typename: 'UserInfo',
							idUserInfo: comment.userInfo === null ? null : comment.userInfo.idUserInfo,
							idUser: comment.userInfo === null ? null : comment.userInfo.idUser,
							displayName: comment.userInfo === null ? null : comment.userInfo.displayName,
							image: {
								__typename: 'PlatformImage',
								id: comment.userInfo === null ? null : comment.userInfo.image.id,
								url: comment.userInfo === null ? null : comment.userInfo.image.url,
							},
						},
					},
				},
			},
			update: (cache, { data }) => {
				const { study } = cache.readQuery({ ...STUDY_NOTES(idStudy) })
				cache.writeQuery({
					...STUDY_NOTES(idStudy),
					data: {
						study: {
							...study,
							idStudy,
							notes: study.notes.map(note => {
								if (note.idNote !== data.study.deleteStudyNote.idNote) {
									return note
								}
								return data.study.deleteStudyNote
							}),
						},
					},
				})
			},
			options: { onError },
		},
		[note],
	)
}

export const MARK_AS_DONE = (task, onError) => {
	const {
		idStudy,
		idNote,
		task: { isDone },
	} = task

	return prepareMutation(
		{
			mutation: gql`
				mutation MARK_AS_DONE($idStudy: UUID!, $idNote: UUID!, $isDone: Boolean!) {
					study {
						markAsDone(idStudy: $idStudy, idNote: $idNote, isDone: $isDone) {
							...note
						}
					}
				}
			`,
			options: { onError },
			variables: { idStudy, idNote, isDone },
			optimisticResponse: {
				__typename: 'Mutation',
				study: {
					__typename: 'StudyMutation',
					markAsDone: {
						__typename: 'StudyNote',
						idNote,
						idStudy,
						idItem: task.idItem,
						idParentNote: task.idParentNote,
						type: STUDY_NOTE_TYPES.VISUAL_FLOW,
						text: task.text,
						task: {
							__typename: 'Task',
							isTask: true,
							isDone: isDone === false,
						},
						meta: {
							__typename: 'Meta',
							created: task.meta.created,
							updated: task.meta.updated,
						},
						userInfo: {
							__typename: 'UserInfo',
							idUserInfo: task.userInfo === null ? null : task.userInfo.idUserInfo,
							idUser: task.userInfo === null ? null : task.userInfo.idUser,
							displayName: task.userInfo === null ? null : task.userInfo.displayName,
							image: {
								__typename: 'PlatformImage',
								id: task.userInfo === null ? null : task.userInfo.image.id,
								url: task.userInfo === null ? null : task.userInfo.image.url,
							},
						},
					},
				},
			},
			update: (cache, { data }) => {
				const { study } = cache.readQuery({ ...STUDY_NOTES(idStudy) })
				cache.writeQuery({
					...STUDY_NOTES(idStudy),
					data: {
						study: {
							...study,
							idStudy,
							notes: study.notes.map(note => {
								if (note.idNote !== data.study.markAsDone.idNote) {
									return note
								}
								return data.study.markAsDone
							}),
						},
					},
				})
			},
		},
		[note],
	)
}

export const DELETE_STUDY_TASK = (idStudy, idNote, onError) =>
	prepareMutation({
		mutation: gql`
			mutation DELETE_STUDY_TASK($idStudy: UUID!, $idNote: UUID!) {
				study {
					deleteStudyTask(idStudy: $idStudy, idNote: $idNote)
				}
			}
		`,
		optimisticResponse: {
			__typename: 'Mutation',
			study: {
				__typename: 'StudyMutation',
				deleteStudyTask: {
					__typename: 'StudyNote',
					idNote,
				},
			},
		},
		update: cache => {
			const { study } = cache.readQuery({ ...STUDY_NOTES(idStudy) })
			cache.writeQuery({
				...STUDY_NOTES(idStudy),
				data: {
					study: {
						...study,
						idStudy,
						notes: study.notes.filter(note => idNote.includes(note.idNote) === false),
					},
				},
			})
		},
		options: { onError },
	})

export const UPDATE_STUDY_NOTE = (idStudy, onCompleted, onError) =>
	prepareMutation(
		{
			mutation: gql`
				mutation UPDATE_STUDY_NOTE($idStudy: UUID!, $idNote: UUID!, $text: String!) {
					study {
						updateStudyNote(idStudy: $idStudy, idNote: $idNote, text: $text) {
							...note
						}
					}
				}
			`,

			update: (cache, { data }) => {
				const { study } = cache.readQuery({ ...STUDY_NOTES(idStudy) })
				cache.writeQuery({
					...STUDY_NOTES(idStudy),
					data: {
						study: {
							...study,
							idStudy,
							notes: study.notes.map(note => {
								if (note.idNote !== data.study.updateStudyNote.idNote) {
									return note
								}
								return data.study.updateStudyNote
							}),
						},
					},
				})
			},
			options: { onCompleted, onError },
		},
		[note],
	)

export const UPDATE_FLOW_LABELS = () =>
	prepareMutation({
		mutation: gql`
			mutation UPDATE_FLOW_LABELS($idStudy: UUID!, $updateFlowLabelsInput: UpdateFlowLabelsInput!) {
				study {
					updateFlowLabels(idStudy: $idStudy, updateFlowLabelsInput: $updateFlowLabelsInput) {
						idStudy
						...flow
					}
				}
			}

			${flow}
		`,
	})

export const CREATE_QUANT_QUAL_SLIDE = () =>
	prepareMutation({
		mutation: gql`
			mutation CREATE_QUANT_QUAL_SLIDE(
				$idStudy: UUID!
				$idSlide: UUID!
				$idAIOpenEndQuestion: UUID!
				$idChoiceQuestion: UUID!
				$idsSegments: [UUID!]!
				$title: String!
				$isThemeViewActive: Boolean!
			) {
				study {
					createQuantQualSlide(
						idStudy: $idStudy
						idSlide: $idSlide
						idAIOpenEndQuestion: $idAIOpenEndQuestion
						idChoiceQuestion: $idChoiceQuestion
						idsSegments: $idsSegments
						title: $title
						isThemeViewActive: $isThemeViewActive
					) {
						...quantQualSlide
					}
				}
			}

			${quantQualSlide}
		`,
		update: (cache, { data }) => {
			try {
				const { createQuantQualSlide: newSlide } = data.study
				const { idStudy } = newSlide
				const { study } = cache.readQuery({ ...GET_STUDY_QUANT_QUAL_SLIDES(idStudy) })
				cache.writeQuery({
					...GET_STUDY_QUANT_QUAL_SLIDES(idStudy),
					data: {
						study: {
							...study,
							quantQualSlides: [...study.quantQualSlides, newSlide],
						},
					},
				})
			} catch (error) {
				if (error.message.includes(`Can't find field`) === true) {
					// do nothing, we did not call slides query so there's nothing to update
					return
				}

				logError(error)
			}
		},
	})

export const UPDATE_QUANT_QUAL_SLIDE = () =>
	prepareMutation({
		mutation: gql`
			mutation UPDATE_QUANT_QUAL_SLIDE(
				$idStudy: UUID!
				$idSlide: UUID!
				$slideSettings: QuantQualSlideSettingsInput!
			) {
				study {
					updateQuantQualSlide(
						idStudy: $idStudy
						idSlide: $idSlide
						slideSettings: $slideSettings
					) {
						...quantQualSlide
					}
				}
			}

			${quantQualSlide}
		`,
	})

export const DELETE_QUANT_QUAL_SLIDE = (idStudy, idSlide, onCompleted, onError) =>
	prepareMutation({
		variables: {
			idStudy,
			idSlide,
		},
		options: { onCompleted, onError },
		mutation: gql`
			mutation DELETE_QUANT_QUAL_SLIDE($idStudy: UUID!, $idSlide: UUID!) {
				study {
					deleteQuantQualSlide(idStudy: $idStudy, idSlide: $idSlide)
				}
			}
		`,
		update: cache => {
			try {
				const { study } = cache.readQuery({ ...GET_STUDY_QUANT_QUAL_SLIDES(idStudy) })
				cache.writeQuery({
					...GET_STUDY_QUANT_QUAL_SLIDES(idStudy),
					data: {
						study: {
							...study,
							quantQualSlides: study.quantQualSlides.filter(slide => slide.idSlide !== idSlide),
						},
					},
				})
			} catch (error) {
				if (error.message.includes(`Can't find field`) === true) {
					// do nothing, we did not call slides query so there's nothing to update
					return
				}

				logError(error)
			}
		},
	})

export const EXPORT_ANSWERS_TRANSLATIONS = () => {
	return prepareMutation({
		mutation: gql`
			mutation EXPORT_ANSWERS_TRANSLATIONS($idStudy: UUID!) {
				study {
					translations {
						exportStatementsTranslation(idStudy: $idStudy) {
							url
						}
					}
				}
			}
		`,
	})
}

export const IMPORT_ANSWERS_TRANSLATIONS = () => {
	return prepareMutation({
		mutation: gql`
			mutation IMPORT_ANSWERS_TRANSLATIONS($idStudy: UUID!, $statementsXlsx: String!) {
				study {
					translations {
						importStatementsTranslation(idStudy: $idStudy, statementsXlsx: $statementsXlsx) {
							success
						}
					}
				}
			}
		`,
	})
}

export const EXPORT_FLOW_TRANSLATIONS = () => {
	return prepareMutation({
		mutation: gql`
			mutation EXPORT_FLOW_TRANSLATIONS($idStudy: UUID!) {
				study {
					translations {
						exportFlowTranslation(idStudy: $idStudy) {
							visualFlowUrl
						}
					}
				}
			}
		`,
	})
}

export const IMPORT_FLOW_TRANSLATIONS = () => {
	return prepareMutation({
		mutation: gql`
			mutation IMPORT_FLOW_TRANSLATIONS($idStudy: UUID!, $visualFlowXlsx: String!) {
				study {
					translations {
						importFlowTranslation(idStudy: $idStudy, visualFlowXlsx: $visualFlowXlsx)
					}
				}
			}
		`,
	})
}
