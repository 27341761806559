import { gql } from 'graphql-tag'

export const segmentationTypingTool = gql`
	fragment segmentationTypingTool on SegmentationTypingTool {
		idSegmentationTypingTool
		name
		meta {
			updated
		}
		rules {
			idSegmentationTypingToolRule
			name
			conditions
			constants {
				idSegmentationTypingToolSegment
				value
			}
		}
		segments {
			idSegmentationTypingToolSegment
			constant
			name
			size
		}
	}
`

export const segmentationTypingToolPublicLiveSlides = gql`
	fragment segmentationTypingToolPublicLiveSlides on SegmentationTypingTool {
		idSegmentationTypingTool
		name
		meta {
			updated
		}
		segments {
			idSegmentationTypingToolSegment
			constant
			name
			size
		}
	}
`
