import { gql } from 'graphql-tag'

export const mediaObject = gql`
	fragment mediaObject on MediaObject {
		idMediaObject
		url
		filename
		type
		errors
		isUploadFinished
		isPostProcessingFinished
	}
`
