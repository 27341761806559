import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { hasBlobData } from 'helpers/reportBuilder/hasBlobData'
import { normalizeInput } from 'helpers/string/normalizeInput'
import { REPORT_TYPES, REPORT_BLOB_TYPES } from 'constants/reports'
import { REPORT_BUILDER_COLORS } from 'constants/reportBuilderColors'
import { createLines } from 'helpers/reportBuilder/chart/createLines'

const calculateIdeas = (idStudy, reportType, slideSettings, legendState, blobData) => {
	if (reportType !== REPORT_TYPES.FREE_TEXT) {
		return []
	}

	if (slideSettings === undefined) return []
	const { idStudyObject, idsSegments } = slideSettings
	const [idSegment] = idsSegments

	const data =
		blobData[
			getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.FREE_TEXT_STATISTICS, idSegment)
		]

	if (hasBlobData([data], 'answers') === false) {
		return []
	}

	const ideas = data.answers
		.filter(({ answer }) => answer !== null && answer !== '' && answer.trim().length > 0)
		.map(({ answer }) => {
			const text = answer

			const lengthRangeTo = 51
			const lengthLimit = 71

			const ideaLines = createLines(text, lengthRangeTo, lengthLimit)

			return ideaLines.join('\n')
		})

	// ideas is array of strings
	return ideas.map(idea => ({
		name: idea,
		toExport: () => [idea],
	}))
}

export const calculateAllWordCloudData = (
	idStudy,
	reportType,
	slideSettings,
	legendState,
	blobData,
) => {
	const allAnswers = calculateIdeas(idStudy, reportType, slideSettings, legendState, blobData)

	const ideasCounts = {}

	allAnswers.forEach(answer => {
		const key = normalizeInput(answer.name)

		if (ideasCounts[key] === undefined) {
			ideasCounts[key] = { value: 1, name: answer.name }
		} else {
			ideasCounts[key] = { value: ideasCounts[key].value + 1, name: answer.name }
		}
	})

	return Object.values(ideasCounts).map(({ value, name }, index) => {
		const colorIndex = index % REPORT_BUILDER_COLORS.length

		return {
			// include name so we can sorty by it
			name,
			value,
			color: REPORT_BUILDER_COLORS[colorIndex],
			toExport: () => [name],
			isExcluded: slideSettings.options.includes(name) === true,
		}
	})
}
