import { Ajv } from './ajvImport'
import OBJECT_TYPES from './OBJECT_TYPES'
import moduleIntro from './schemas/MODULE_INTRO'

// modules
import allocationSchema from './schemas/ALLOCATION'
import choiceSchema from './schemas/CHOICE'
import endStudySchema from './schemas/END_STUDY'
import evaluatorSchema from './schemas/EVALUATOR'
import freeTextSchema from './schemas/FREE_TEXT'
import heatmapSchema from './schemas/HEATMAP'
import listSchema from './schemas/LIST'
import maxDiffSchema from './schemas/MAXDIFF'
import messageSchema from './schemas/MESSAGE'
import oeqSchema from './schemas/OEQ'
import ranking from './schemas/RANKING'
import researchLabeling from './schemas/RESEARCH_LABELING'
import uiCommandSchema from './schemas/UI_COMMAND'
// includes
import actionButtonSchema from './schemas/ACTION_BUTTON'
import evaluatorIfObjectSchema from './schemas/EVALUATOR_IF'
import evaluatorSetLiteralObjectSchema from './schemas/EVALUATOR_SET_LITERAL'
import evaluatorSetObjectSchema from './schemas/EVALUATOR_SET'
import mediaObjectSchema from './schemas/MEDIA_OBJECT'
// code validators
import choiceUniqueLabels from './validators/choiceUniqueLabels'
import choiceUniqueSimplenames from './validators/choiceUniqueSimplenames'
import duplicateModuleIds from './validators/duplicateModuleIds'
import duplicateModuleSimplename from './validators/duplicateModuleSimplename'
import emptyUICommand from './validators/emptyUICommand'
import entryPoint from './validators/entryPoint'
import evaluator from './validators/evaluator'
import flowLimits from './validators/flowLimits'
import heatmapMinMaxSelection from './validators/heatmapMinMaxSelection'
import heatmapEmptyImage from './validators/heatmapEmptyImage'
import invalidPiping from './validators/invalidPiping'
import mediaObjectImage from './validators/mediaObjectImage'
import mediaObjectVideo from './validators/mediaObjectVideo'
import nextStepExists from './validators/nextStepExists'
import upperCaseIds from './validators/upperCaseIds'

const ajv = Ajv({ allErrors: true })

export const validators = {
	[OBJECT_TYPES.EVALUATOR_SET_LITERAL]: {
		validate: ajv.compile(evaluatorSetLiteralObjectSchema),
		codeValidators: [],
	},
	[OBJECT_TYPES.EVALUATOR_SET]: {
		validate: ajv.compile(evaluatorSetObjectSchema),
		codeValidators: [],
	},
	[OBJECT_TYPES.EVALUATOR_IF]: {
		validate: ajv.compile(evaluatorIfObjectSchema),
		codeValidators: [],
	},
	[OBJECT_TYPES.ACTION_BUTTON]: {
		validate: ajv.compile(actionButtonSchema),
		codeValidators: [],
	},
	[OBJECT_TYPES.MEDIA_OBJECT]: {
		validate: ajv.compile(mediaObjectSchema),
		codeValidators: [],
	},
	FLOW: {
		codeValidators: [
			{ flowLimits },
			{ upperCaseIds },
			{ duplicateModuleIds },
			{ duplicateModuleSimplename },
			{ entryPoint },
			{ nextStepExists },
			{ invalidPiping },
		],
	},
	MODULE_INTRO: {
		validate: ajv.compile(moduleIntro),
		codeValidators: [],
	},
	// MESSAGE is referenced in other schemas so we have to compile it before
	// dataset modules
	[OBJECT_TYPES.MESSAGE]: {
		validate: ajv.compile(messageSchema),
		codeValidators: [{ mediaObjectImage }, { mediaObjectVideo }],
	},
	[OBJECT_TYPES.OEQ]: {
		validate: ajv.compile(oeqSchema),
		codeValidators: [],
	},
	[OBJECT_TYPES.CHOICE]: {
		validate: ajv.compile(choiceSchema),
		codeValidators: [{ choiceUniqueLabels }, { choiceUniqueSimplenames }],
	},
	[OBJECT_TYPES.FREE_TEXT]: {
		validate: ajv.compile(freeTextSchema),
		codeValidators: [],
	},
	[OBJECT_TYPES.END_STUDY]: {
		validate: ajv.compile(endStudySchema),
		codeValidators: [],
	},
	[OBJECT_TYPES.HEATMAP]: {
		validate: ajv.compile(heatmapSchema),
		codeValidators: [{ heatmapMinMaxSelection }, { heatmapEmptyImage }],
	},
	[OBJECT_TYPES.UI_COMMAND]: {
		validate: ajv.compile(uiCommandSchema),
		codeValidators: [{ emptyUICommand }],
	},
	[OBJECT_TYPES.EVALUATOR]: {
		validate: ajv.compile(evaluatorSchema),
		codeValidators: [{ evaluator }],
	},
	[OBJECT_TYPES.RESEARCH_LABELING]: {
		validate: ajv.compile(researchLabeling),
		codeValidators: [],
	},
	[OBJECT_TYPES.RANKING]: {
		validate: ajv.compile(ranking),
		codeValidators: [{ choiceUniqueLabels }, { choiceUniqueSimplenames }],
	},
	[OBJECT_TYPES.ALLOCATION]: {
		validate: ajv.compile(allocationSchema),
		codeValidators: [{ choiceUniqueLabels }, { choiceUniqueSimplenames }],
	},
	[OBJECT_TYPES.LIST]: {
		validate: ajv.compile(listSchema),
		codeValidators: [],
	},
	[OBJECT_TYPES.MAXDIFF]: {
		validate: ajv.compile(maxDiffSchema),
		codeValidators: [{ choiceUniqueLabels }, { choiceUniqueSimplenames }],
	},
}

export default validators
