import { IPM_SUPPORTED_LANGUAGES } from 'constants/study'

/**
 * @typedef {1 | 2 | 3 | 4 | 5} StudyVersion
 * @typedef {{language: string, version: StudyVersion}} StudyLike
 */

/**
 * @param {StudyVersion} version
 *
 * @returns {boolean}
 */
const getIsVersionIpmCompatible = version => version >= 4

/**
 * @param {StudyLike}
 *
 * @returns {boolean}
 */
export const getIsStudyAutoTranslatable = ({ language, version }) =>
	getIsVersionIpmCompatible(version) && IPM_SUPPORTED_LANGUAGES.includes(language) === false

/**
 * @param {StudyLike}
 *
 * @returns {boolean}
 */
export const getIsIpmClustersCompatible = ({ version, language }) =>
	getIsVersionIpmCompatible(version) && IPM_SUPPORTED_LANGUAGES.includes(language) === true
