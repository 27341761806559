const schema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	$id: 'http://groupsolver.com/v1/ALLOCATION.json',
	type: 'object',
	additionalProperties: false,
	definitions: {},
	required: [
		'filter',
		'hintValueSettings',
		'idRelatedMessage',
		'limit',
		'mandatory',
		'options',
		'range',
		'subtype',
		'messages',
	],
	properties: {
		idRelatedMessage: {
			$id: '/properties/idRelatedMessage',
			pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
			type: ['string', 'null'],
		},
		mandatory: {
			default: true,
			$id: '/properties/mandatory',
			type: 'boolean',
		},
		filter: {
			$id: '/properties/multiple',
			type: ['string', 'null'],
		},
		subtype: {
			$id: '/properties/subtype',
			enum: ['INPUT'],
			type: 'string',
		},
		hintValueSettings: {
			$id: '/properties/hintValueSettings',
			type: 'object',
			additionalProperties: false,
			required: ['unitLabel', 'addSpace', 'isLeft'],
			properties: {
				unitLabel: {
					$id: '/properties/hintValueSettings/unitLabel',
					type: 'string',
				},
				addSpace: {
					$id: '/properties/hintValueSettings/addSpace',
					type: 'boolean',
				},
				isLeft: {
					$id: '/properties/hintValueSettings/isLeft',
					type: 'boolean',
				},
			},
		},
		limit: {
			$id: '/properties/limit',
			type: 'object',
			additionalProperties: false,
			required: ['isEnabled', 'value', 'useLimitAsMin'],
			properties: {
				isEnabled: {
					$id: '/properties/limit/isEnabled',
					type: 'boolean',
				},
				useLimitAsMin: {
					$id: '/properties/limit/useLimitAsMin',
					type: 'boolean',
				},
				value: {
					$id: '/properties/limit/value',
					type: 'object',
					additionalProperties: false,
					required: ['type', 'value'],
					properties: {
						type: {
							$id: '/properties/limit/value/type',
							enum: ['VALUE', 'VARIABLE', 'FREE_TEXT_ANSWER'],
							type: 'string',
						},
						value: {
							$id: '/properties/limit/value/value',
							type: ['string', 'integer'],
						},
					},
				},
			},
		},
		range: {
			$id: '/properties/range',
			type: 'object',
			additionalProperties: false,
			required: ['max', 'min', 'step'],
			properties: {
				max: {
					$id: '/properties/range/max',
					type: ['integer', 'null'],
				},
				min: {
					$id: '/properties/range/min',
					type: 'integer',
				},
				step: {
					$id: '/properties/range/step',
					type: 'integer',
				},
			},
		},
		options: {
			additionalItems: false,
			$id: '/properties/options',
			minItems: 1,
			type: 'array',
			uniqueItems: true,
			items: {
				$id: '/properties/options/items',
				type: 'object',
				additionalProperties: false,
				required: [
					'code',
					'isNoneOfThese',
					'label',
					'media',
					'randomize',
					'showLabel',
					'simpleName',
					'defaultValue',
					'id',
					'isHidden',
				],
				properties: {
					id: {
						$id: '/properties/options/items/properties/id',
						pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
						type: 'string',
					},
					code: {
						$id: '/properties/options/items/properties/code',
						type: 'integer',
					},
					defaultValue: {
						$id: '/properties/options/items/properties/default',
						type: ['integer', 'string'],
					},
					showLabel: {
						$id: '/properties/options/items/properties/showLabel',
						type: 'boolean',
						default: true,
					},
					label: {
						$id: '/properties/options/items/properties/label',
						type: 'string',
					},
					media: {
						$id: '/properties/options/items/properties/media',
						type: 'null',
					},
					randomize: {
						$id: '/properties/options/items/properties/randomize',
						type: 'boolean',
						default: false,
					},
					isNoneOfThese: {
						default: false,
						$id: '/properties/options/items/properties/isNoneOfThese',
						type: 'boolean',
					},
					simpleName: {
						$id: '/properties/options/items/properties/simpleName',
						type: ['string', 'null'],
					},
					isHidden: {
						default: false,
						$id: '/properties/options/items/properties/isHidden',
						type: 'boolean',
					},
				},
			},
		},
		messages: {
			$id: '/properties/messages',
			type: 'array',
			additionalItems: false,
			uniqueItems: true,
			items: {
				$id: '/properties/messages/items',
				type: 'object',
				additionalProperties: false,
				required: ['id', 'definition', 'type'],
				properties: {
					id: {
						$id: '/properties/messages/items/id',
						type: 'string',
						pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
					},
					type: {
						$id: '/properties/messages/items/type',
						type: 'string',
						enum: ['A_MESSAGE'],
					},
					definition: {
						$id: '/properties/messages/items/definition',
						$ref: 'http://groupsolver.com/v1/MESSAGE.json',
					},
				},
			},
		},
		dynamicOptionsSettings: {
			$id: '/properties/dynamicOptionsSettings',
			type: 'object',
			required: ['isActive', 'idMainList', 'idInputList', 'optionLabelColumn', 'optionsOrderMode'],
			additionalProperties: false,
			properties: {
				isActive: {
					$id: '/properties/dynamicOptionsSettings/isActive',
					type: 'boolean',
				},
				idMainList: {
					$id: '/properties/dynamicOptionsSettings/idMainList',
					type: ['string', 'null'],
				},
				idInputList: {
					$id: '/properties/dynamicOptionsSettings/idInputList',
					type: ['string', 'null'],
				},
				optionLabelColumn: {
					$id: '/properties/dynamicOptionsSettings/optionLabelColumn',
					type: ['string', 'null'],
				},
				optionsOrderMode: {
					$id: '/properties/dynamicOptionsSettings/optionsOrderMode',
					type: 'string',
					enum: ['QUESTION_ORDER', 'INPUT_LIST_ORDER'],
				},
			},
		},
	},
}

export default schema
