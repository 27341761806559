import React from 'react'

import { useQuery } from 'react-apollo'
import { USER_PAGE } from 'gql/queries/user'

import LoadingStatus from 'components/_scaffolding/LoadingStatus'
import StaticBlockingOverlay from 'components/_overlay/StaticBlockingOverlay'

const getUser = WrappedComponent => {
	const GetUserHOC = props => {
		const { loading, error, data, refetch } = useQuery(USER_PAGE().query)

		if (loading === true || error !== undefined) {
			return (
				<StaticBlockingOverlay>
					<LoadingStatus loading />
				</StaticBlockingOverlay>
			)
		}

		const { user, accounts } = data

		if (user === undefined) {
			refetch()
			return (
				<StaticBlockingOverlay>
					<LoadingStatus loading />
				</StaticBlockingOverlay>
			)
		}

		return <WrappedComponent user={user} accounts={accounts} {...props} />
	}

	GetUserHOC.displayName = `GetUserHOC(${WrappedComponent.displayName || WrappedComponent.name})`

	return GetUserHOC
}

export default getUser
