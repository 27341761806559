import PropTypes from 'prop-types'
import React, { Suspense, useEffect } from 'react'
import { ApolloProvider } from 'react-apollo'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import config from 'config'

import { userClient } from 'store/tools/graphql'

import { DEFAULT_LANGUAGE, messages } from './intl'

import LoadingStatus from 'components/_scaffolding/LoadingStatus'

const renderChildRoutes = routes =>
	routes.map((route, index) => (
		<Route path={route.path} element={route.element} key={index}>
			{Array.isArray(route.children) === true && renderChildRoutes(route.children)}
		</Route>
	))

// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/webdriver
// better than isCypressTesting & ise2eTesting as both cypress and selenium have
// set navigator.webdriver to true
const canInitGoogleTagManager = navigator.webdriver !== true

const initGoogleTagManager = () => {
	TagManager.initialize({
		gtmId: config.googleTagManagerId,
	})
}

if (canInitGoogleTagManager === true) {
	initGoogleTagManager()
}

const App = ({ routes, store }) => {
	useEffect(() => {
		// appActions object is used to expose functions for cypress testing interactions
		if (config.isTesting === true) {
			window.appActions = {}
		}
	}, [])

	return (
		<Provider store={store}>
			<ApolloProvider client={userClient}>
				<IntlProvider locale={DEFAULT_LANGUAGE} messages={messages}>
					<Suspense fallback={<LoadingStatus loading />}>
						<BrowserRouter>
							<Routes>
								<Route element={routes.element}>{renderChildRoutes(routes.children)}</Route>
							</Routes>
						</BrowserRouter>
					</Suspense>
				</IntlProvider>
			</ApolloProvider>
		</Provider>
	)
}

App.propTypes = {
	routes: PropTypes.object.isRequired,
	store: PropTypes.object.isRequired,
}

export default App
