import config from 'config'
import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'
import { PREPARATION_TYPES } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/PREPARATION_TYPES'

export const links = {
	CONTACT_US: 'https://www.groupsolver.com/contact/',
	DEFAULT_ACCOUNT_IMAGE: `${config.publicDashboardDataBucketUrl}/_defaults/account-image.png`,
	DEFAULT_USER_IMAGE: `${config.publicDashboardDataBucketUrl}/_defaults/user-image.png`,
	GROUPSOLVER: 'https://groupsolver.com',
	GROUPSOLVER_BLOG: 'https://www.groupsolver.com/blog/',
	GROUPSOLVER_RESEARCH_GRANTS: 'https://groupsolver.com/research-grants/',
	GROUPSOLVER_LOGO:
		'https://s3.amazonaws.com/groupsolver-gs3prod-images/fileStore/a6627116-de8b-4f60-b908-8b08b5689d4b',
	GROUPSOLVER_LOGO_SMALL: `${config.publicProjectDataBucketUrl}/presentation/_default/liveslides-footer-icon.png`,
	HELP_USER_IMAGE: `${config.publicDashboardDataBucketUrl}/_defaults/user-help.png`,
	HOW_DOES_QUANT_QUAL_WORK: 'https://help.groupsolver.com/en/articles/6627593-quantqual',
	KNOWLEDGE_BASE: 'https://help.groupsolver.com/en',
	NEED_HELP_CONTACT: 'https://share.hsforms.com/1trKW3Z7gRy2E4IFS4B3CCA1513o',
	PRIVACY_POLICY: `${config.publicLegalDataBucketUrl}/privacy-policy.html`,
	PRIVACY_POLICY_WEB: 'https://www.groupsolver.com/privacy-policy/',
	TERMS_AND_CONDITIONS: `${config.publicLegalDataBucketUrl}/terms-and-conditions.html`,
}

export const KNOWLEDGE_BASE_LINKS = {
	[VISUAL_FLOW_MODULE_TYPES.A_MESSAGE]: 'https://help.groupsolver.com/en/articles/6539128-message',
	[VISUAL_FLOW_MODULE_TYPES.A_END_STUDY]:
		'https://help.groupsolver.com/en/articles/6896101-endpoints',
	[VISUAL_FLOW_MODULE_TYPES.MATRIX_CHOICE]:
		'https://help.groupsolver.com/en/articles/6539131-matrix-questions',
	[VISUAL_FLOW_MODULE_TYPES.A_CHOICE]:
		'https://help.groupsolver.com/en/articles/6539130-choice-questions',
	[PREPARATION_TYPES.CONDITION]: 'https://help.groupsolver.com/en/articles/6896082-conditions',
	[PREPARATION_TYPES.RANDOMIZER]:
		'https://help.groupsolver.com/en/articles/6539168-randomizing-question-order',
	[PREPARATION_TYPES.TRACK_RANDOMIZER]:
		'https://help.groupsolver.com/en/articles/6539118-glossary#h_42c9dd2d06',
	[PREPARATION_TYPES.QUOTA]: 'https://help.groupsolver.com/en/articles/6896090-quota',
	[PREPARATION_TYPES.BLOCK]:
		'https://help.groupsolver.com/en/articles/6539118-glossary#h_0fa07ae826',
	[PREPARATION_TYPES.HISTORY]: 'https://help.groupsolver.com/en/articles/6896096-history-filter',
	[PREPARATION_TYPES.PROCEED]: 'https://help.groupsolver.com/en/articles/6896106-proceed',
	[VISUAL_FLOW_MODULE_TYPES.A_OEQ]: 'https://help.groupsolver.com/en/articles/6539127-ai-open-ends',
	[VISUAL_FLOW_MODULE_TYPES.ALLOCATION]:
		'https://help.groupsolver.com/en/articles/6539132-allocation-questions',
	[VISUAL_FLOW_MODULE_TYPES.RANKING]:
		'https://help.groupsolver.com/en/articles/6539133-ranking-questions',
	BUILDING_SEGMENTS: 'https://help.groupsolver.com/en/articles/6539144-building-segments',
	DATA_IMPORTER: 'https://help.groupsolver.com/en/articles/6539148-importing-data',
	COMMUNITY: 'http://help.groupsolver.com/en/articles/7300730-community',
	LIVE_SLIDES: 'https://help.groupsolver.com/en/collections/3706731-liveslides',
	[VISUAL_FLOW_MODULE_TYPES.LIST]: 'http://help.groupsolver.com/en/articles/7300729-list',
	[PREPARATION_TYPES.LOOP]: 'http://help.groupsolver.com/en/articles/7300721-loop',
}

export const HOME_LINKS = {
	WHAT_MAKES_GROUPSOLVER_UNIQUE:
		'https://help.groupsolver.com/en/articles/6539115-groupsolver-open-ended-approach',
	HOW_TO_DESIGN_A_SURVEY:
		'https://help.groupsolver.com/en/articles/6539112-how-do-i-begin-programming-a-groupsolver-study',
	REVIEW_AND_ANALYZE_SURVEY_DATA:
		'https://help.groupsolver.com/en/articles/6539111-how-do-i-find-and-analyze-my-data-post-survey',
}
