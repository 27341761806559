import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import { canBeUsedAsDefaultConditionStudyObject } from 'helpers/conditionBuilder/canBeUsedAsDefaultConditionStudyObject'
import { handleRandomizerCountChange } from 'helpers/visualFlowModules/handleRandomizerCountChange'
import getOrderModuleById from 'routes/_study/StudyDesign/_store/helpers/getOrderModuleById'
import hasInvalidModuleFilter from 'routes/_study/StudyDesign/_store/helpers/flowValidation/validateModule/_hasInvalidModuleFilter'
import { getConditionBuilderModules } from 'helpers/conditionBuilder/getConditionBuilderModules'
import { getIsStudyEditable } from 'helpers/studyList/getIsStudyEditable'

import { useActiveStudyQuery } from 'hooks/useActiveStudyQuery'

import Checkbox from 'components/_formik/_base/Checkbox'
import Input from 'components/_formik/_base/Input'
import Label from 'components/_formik/_base/Label'
import Slider from 'components/_formik/_base/Slider'
import ConditionBuilder from 'components/_formik/_complex/ConditionBuilder'

import Tooltip from 'components/_scaffolding/Tooltip'

import DatasetShortName from 'components/_formik/_custom/_studyDesign/DatasetShortName'
import QuotaDefinition from 'components/_formik/_custom/_studyDesign/QuotaDefinition'

const Conditional = props => {
	const intl = useIntl()

	const { study } = useActiveStudyQuery()

	if (study === null) {
		return null
	}

	const isStudyEditable = getIsStudyEditable(props.studyState)
	const isQuotaCheckpoint = props.values.enableQuota
	const isTrackModule = props.values.isTrack
	const isBlockModule = props.values.isBlock
	const isRandomizer = props.values.isRandomizer
	const isTrackRandomizer = props.values.isTrackRandomizer

	const conditionsLabel =
		isQuotaCheckpoint === true
			? intl.formatMessage({ id: 'quota.conditions_label' })
			: intl.formatMessage({ id: 'condition.conditions_label' })

	// skip other condition modules and choices generated in matrix
	// when assigning default study object to new condition on condition add
	const upperChoiceModules = props.upperModules.filter(canBeUsedAsDefaultConditionStudyObject)
	const defaultChoiceModule = upperChoiceModules[upperChoiceModules.length - 1]

	const getShortNameInputTranslations = () => {
		if (isTrackModule === true) {
			return 'checkpoint.conditional.name.track'
		}

		if (isBlockModule === true) {
			return 'checkpoint.conditional.name.block'
		}

		if (isRandomizer === true) {
			return 'checkpoint.conditional.name.randomizer'
		}

		if (isTrackRandomizer === true) {
			return 'checkpoint.conditional.name.track_randomizer'
		}

		return 'checkpoint.conditional.name.default'
	}

	const renderShortNameInput = () => {
		const canEditShortName =
			isQuotaCheckpoint === false || props.isUnsaved === true || isStudyEditable === true
		const idTooltip = 'quota-name-tooltip'

		return (
			<Fragment>
				<div
					data-tip={intl.formatMessage({ id: 'condition.quota.cannot_edit_name' })}
					data-for={idTooltip}
				>
					<DatasetShortName
						disabled={props.disabled || canEditShortName === false}
						errorInvalidSimpleName={props.errorInvalidSimpleName}
						errorNonUniqueSimpleName={props.errorNonUniqueSimpleName}
						label={intl.formatMessage({ id: getShortNameInputTranslations() })}
						values={props.values}
					/>
				</div>
				{props.disabled !== true && canEditShortName === false && <Tooltip id={idTooltip} />}
			</Fragment>
		)
	}

	if (isBlockModule === true) {
		return renderShortNameInput()
	}

	if (isRandomizer === true) {
		const orderModule = getOrderModuleById(props.values.id, props.order)
		const numberOfChildren = orderModule ? orderModule.then.length : 0

		return (
			<Fragment>
				{renderShortNameInput()}
				{numberOfChildren > 1 && (
					<Fragment>
						<Label
							label={intl.formatMessage(
								{
									id:
										isTrackRandomizer === true
											? 'track_randomizer.modules_shown'
											: 'randomizer.modules_shown',
								},
								{ count: props.values.randomizerCount },
							)}
						/>
						<Field
							component={Slider}
							disabled={props.disabled}
							max={numberOfChildren || 2}
							min={1}
							name="randomizerCount"
							{...Slider.STYLE_PRESETS.light}
							step={1}
							onChange={value => {
								props.setValues({
									...props.values,
									...handleRandomizerCountChange(value, numberOfChildren),
								})
							}}
						/>
						{props.isUserInternalEmployee === true && (
							<Fragment>
								<Label
									label={intl.formatMessage({
										id: 'checkpoint.conditional.module_filter',
									})}
								/>
								<Field
									component={Input}
									disabled={props.disabled}
									name="moduleFilter"
									type="text"
								/>
								{hasInvalidModuleFilter({
									definition: { moduleFilter: props.values.moduleFilter },
								}) === true && (
									<span className="title-error">
										{intl.formatMessage({
											id: 'matrixChoice.detail.filter_questions_error',
										})}
									</span>
								)}
								<Field
									component={Checkbox}
									componentProps={{
										label: intl.formatMessage({ id: 'randomizer.allow_skip' }),
									}}
									disabled={props.disabled}
									name={'randomizerAllowSkip'}
								/>
								<div className="title-info">
									{intl.formatMessage({
										id:
											props.values.randomizerAllowSkip === true
												? 'randomizer.allow_skip.enabled'
												: 'randomizer.allow_skip.disabled',
									})}
								</div>
							</Fragment>
						)}
					</Fragment>
				)}
				{props.hasZeroNestedModules === true && (
					<span className="title-error">
						{intl.formatMessage({
							id:
								isTrackRandomizer === true
									? 'track_randomizer.empty.error'
									: 'randomizer.empty.error',
						})}
					</span>
				)}
			</Fragment>
		)
	}

	const upperModulesObject = {}

	props.upperModules.forEach(module => {
		upperModulesObject[module.definition.id] = module
	})

	const currentModule = props.modules[props.values.id]

	const currentLoopPath =
		currentModule.path !== undefined && currentModule.path.includes('loop')
			? currentModule.path.split('loop')[0]
			: null

	const upperProFlowModules = study.flow.proFlow.filter(proFlowModule => {
		// normal modules are in visual flow
		if (proFlowModule.listSettings.length === 0) {
			return false
		}

		const { idOriginalModule } = proFlowModule.listSettings[0]

		const originalModule = props.modules[idOriginalModule]

		if (
			upperModulesObject[idOriginalModule] !== undefined &&
			originalModule.path?.startsWith(currentLoopPath) !== true
		) {
			return true
		}

		return false
	})

	const availableModules = [
		...props.upperModules,
		...getConditionBuilderModules({
			intl,
			importedModules: study.importedModules.filter(module => module.isCommunity === true),
			// no need to send visualFlow or flatOrder because we have it computed in props.upperModules
			visualFlow: { modules: {}, order: [] },
			flatOrder: [],
			proFlow: upperProFlowModules,
			segmentationTypingTools: [],
			respondentSources: study.respondentSources,
			includeDateModules: false,
		}),
	].filter(
		module =>
			module.isHidden !== true &&
			(module.isLoopedModuleIteration === true ||
				module.path === undefined ||
				module.path.includes('loop') === false ||
				module.path.startsWith(currentLoopPath)),
	)

	return (
		<Fragment>
			{renderShortNameInput()}
			{isQuotaCheckpoint === true && (
				<QuotaDefinition
					disabled={props.disabled}
					fieldNamePrefix="quota"
					idStudy={props.idStudy}
					quota={props.values.quota}
				/>
			)}
			<ConditionBuilder
				createEmptyCondition={false}
				defaultChoiceModule={defaultChoiceModule}
				disabled={props.disabled}
				header={conditionsLabel}
				isQuotaCheckpoint={isQuotaCheckpoint}
				isSegmentBuilder={false}
				modules={availableModules}
				noModulesMessage={intl.formatMessage({ id: 'checkpoint_conditional.no_options' })}
				setFieldValue={props.setFieldValue}
				setTouched={props.setTouched}
				study={study}
				touched={props.touched}
				values={props.values}
			/>
		</Fragment>
	)
}

Conditional.propTypes = {
	disabled: PropTypes.bool.isRequired,
	errorInvalidSimpleName: PropTypes.bool.isRequired,
	errorNonUniqueSimpleName: PropTypes.bool.isRequired,
	hasZeroNestedModules: PropTypes.bool.isRequired,
	idStudy: PropTypes.string.isRequired,
	isUserInternalEmployee: PropTypes.bool.isRequired,
	order: PropTypes.array.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setValues: PropTypes.func.isRequired,
	setTouched: PropTypes.func.isRequired,
	upperModules: PropTypes.array.isRequired,
	touched: PropTypes.object.isRequired,
	values: PropTypes.object.isRequired,
}

export default Conditional
