import { prepareMutation } from 'store/tools/graphql'
import { gql } from 'graphql-tag'

export const ADD_ACCOUNT_MEDIA_OBJECT = variables =>
	prepareMutation({
		variables,
		mutation: gql`
			mutation ADD_ACCOUNT_MEDIA_OBJECT(
				$idAccount: UUID!
				$addMediaObjectInput: AddMediaObjectInput!
			) {
				account {
					addAccountMediaObject(idAccount: $idAccount, addMediaObjectInput: $addMediaObjectInput) {
						idMediaObject
						url
						filename
						type
						errors
						mediaUploadUrl
						isUploadFinished
						isPostProcessingFinished
					}
				}
			}
		`,
	})

export const ADD_USER_MEDIA_OBJECT = variables =>
	prepareMutation({
		variables,
		mutation: gql`
			mutation ADD_USER_MEDIA_OBJECT($addMediaObjectInput: AddMediaObjectInput!) {
				user {
					addUserMediaObject(addMediaObjectInput: $addMediaObjectInput) {
						idMediaObject
						url
						filename
						type
						errors
						mediaUploadUrl
						isUploadFinished
						isPostProcessingFinished
					}
				}
			}
		`,
	})
