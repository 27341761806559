import { gql } from 'graphql-tag'
const userInfo = gql`
	fragment userInfo on User {
		displayName
		email
		fullName
	}
`

const accountUserInformation = gql`
	fragment accountUserInformation on UserInfo {
		idUserInfo
		idUser
		email
		displayName
		fullName
		image {
			id
			url
		}
		roleDefinition {
			isAdmin
		}
		tags {
			ignoreGsMailCheck
		}
	}
`

const userDetail = gql`
	fragment userDetail on User {
		...userInfo
		image {
			id
			url
		}
		passwordLastChangeDate
		phone
	}
	${userInfo}
`

const notificationSettings = gql`
	fragment notificationSettings on User {
		notificationSettings {
			notificationTypes {
				STUDY_STATUS
				STUDY_PROGRESS
			}
			emailNotificationSettings {
				mode
				intervalInHours
				daily {
					atTime
					inTimezone
				}
			}
		}
	}
`

const notification = gql`
	fragment notification on Notification {
		meta {
			created
			updated
		}
		idNotification
		idUser
		idAccount
		idStudy
		dashboardStatus
		emailStatus
		type
		data
	}
`

const recentUpdates = gql`
	fragment recentUpdates on User {
		recentUpdates {
			idRecentUpdate
			idAccount
			label
			updated
			type
		}
	}
`

export {
	accountUserInformation,
	userInfo,
	userDetail,
	notificationSettings,
	notification,
	recentUpdates,
}
