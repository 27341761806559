import { gql } from 'graphql-tag'

import { accountUserInformation } from 'gql/fragments/user'

const accountInfo = gql`
	fragment accountInfo on Account {
		accountName
		meta {
			created
		}
		image {
			id
			url
		}
		tags {
			internal
			test
			quantQualAccessRequested
			quantQualAccessGranted
		}
		contactPerson {
			...accountUserInformation
		}
		hasEmailSenderSettings
	}
	${accountUserInformation}
`

const accountLicense = gql`
	fragment accountLicense on Account {
		license {
			expireDate
			licenseType
			nextSubscriptionLicenseType
			launchedStudies
			researchExpertHoursSpent
			limits {
				allowedStudies
				maxResearchExpertHoursSpent
				study {
					respondents
					previewRespondents
					segments
				}
				flow {
					visualModules
					datasets
					oeq
					quotas
				}
				allowedUsers
				prepaidRespondents
			}
			prepaidCompletedRespondents
		}
	}
`

const roleDefinition = gql`
	fragment roleDefinition on Account {
		roleDefinition {
			isAdmin
		}
	}
`

const billingInfo = gql`
	fragment billingInfo on Account {
		billingInfo {
			billingAddress {
				city
				country
				line1
				line2
				postalCode
				state
			}
			billingEmail
			billingPhone
			companyName
			country
			taxId
			taxIdVerificationStatus
			taxStatus
		}
	}
`
const subscription = gql`
	fragment subscription on Account {
		subscription {
			status
			currentPeriodStart
			currentPeriodEnd
			createdDate
			cancelAtPeriodEnd
			cancelAtDate
			lastPaymentError {
				clientSecret
				amount
				currency
				status
				error
			}
		}
	}
`

const cardInfo = gql`
	fragment cardInfo on Account {
		cardInformation {
			idPaymentMethod
			brand
			expMonth
			expYear
			last4
		}
	}
`

const invitation = gql`
	fragment invitation on Invitation {
		meta {
			created
		}
		idAccount
		idInvitation
		expireTime
		accountRole
		account {
			idAccount
			accountName
			image {
				id
				url
			}
		}
		to {
			email
			image {
				id
				url
			}
		}
	}
`

export {
	accountInfo,
	accountLicense,
	roleDefinition,
	billingInfo,
	subscription,
	cardInfo,
	invitation,
}
