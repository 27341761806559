export const getCurrentDetailBubbles = settings =>
	settings.themeViewSettings.isActive === true
		? settings.themeViewSettings.detailBubbles
		: settings.detailBubbles

export const getCurrentDetailBubblesInstances = settings =>
	getCurrentDetailBubbles(settings).instances

export const getCurrentDefaultTextsKey = themeViewSettings =>
	themeViewSettings.isActive === true
		? 'themeViewSettings.detailBubbles.defaultTexts'
		: 'detailBubbles.defaultTexts'

export const getCurrentStatementPositions = chartDefinition =>
	chartDefinition.settings.themeViewSettings.isActive === true
		? chartDefinition.settings.themeViewSettings.statementPositions
		: chartDefinition.statementPositions

export const getCurrentScales = settings =>
	settings.themeViewSettings.isActive === true ? settings.themeViewSettings.scales : settings.scales
