import { gql } from 'graphql-tag'
import { getIsInPresentationSharePath } from 'helpers/router'

const isInAuthorizedMode = getIsInPresentationSharePath() === false

export const segment = gql`
	fragment segment on Segment {
		idSegment
		idStudy
		isCalculated
		isValid
		label
		segmentSize
		${isInAuthorizedMode === true ? 'conditions' : ''}
	}
`
